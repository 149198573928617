import { useQuery } from '@tanstack/react-query';

import { getPlayerCardData } from '../../services/props.service.api';
import QuickPickQueryKeys from '../constants/query';
import { useQuickPicksSettings } from './useQuickPicksSettings';

export const useQuickPicksPlayerCard = (id: string) => {
  const settingsQuery = useQuickPicksSettings();

  const { data, isLoading, error } = useQuery({
    queryKey: QuickPickQueryKeys.QUICK_PICKS_PLAYER(id),
    queryFn: () => getPlayerCardData(id, settingsQuery.data?.state_config.disallowed ?? []),
    enabled: !!id,
  });

  return { data, isLoading, error };
};
