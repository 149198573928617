import { useCallback, useEffect, useRef, useState } from 'react';

function useError() {
  const [error, setError] = useState<string | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const showError = useCallback((message: string, timeout?: number) => {
    setError(message);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (timeout) {
      timerRef.current = setTimeout(() => {
        setError(null);
      }, timeout);
    }
  }, []);

  const clearError = useCallback(() => {
    setError(null);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  return { error, showError, clearError };
}

export default useError;
