import { useInfiniteQuery } from '@tanstack/react-query';

import { STALE_TIME } from '~/domains/contest/constants/query';

import type { GetPropsFiltersPayload } from '../../services/interfaces/propsService/api';
import type { TGetPropsResponse } from '../../services/interfaces/propsService/parsed/props';
import { getProps } from '../../services/props.service.api';
import QuickPickQueryKeys from '../constants/query';
import { useQuickPicksSettings } from './useQuickPicksSettings';

const PAGE_SIZE = 100;

const prepareFilters = ({
  search,
  game,
  playerCardId,
  ...restFilters
}: GetPropsFiltersPayload = {}) => ({
  ...restFilters,
  ...(search?.trim() ? { search: search.trim() } : {}),
  ...(game && game.length ? { game } : {}),
});

export const useQuickPicksProps = ({
  filters,
  enabled = true,
  onFirstPageSuccess,
}: {
  filters: GetPropsFiltersPayload;
  enabled?: boolean;
  onFirstPageSuccess?: (data: TGetPropsResponse) => void;
}) => {
  const preparedFilters = prepareFilters(filters);
  const settingsQuery = useQuickPicksSettings();

  return useInfiniteQuery({
    queryKey: QuickPickQueryKeys.QUICK_PICKS(preparedFilters),
    queryFn: ({ pageParam = 0 }) =>
      getProps({
        limit: PAGE_SIZE,
        offset: pageParam,
        ...preparedFilters,
        disallowed: settingsQuery.data?.state_config.disallowed ?? [],
      }),
    staleTime: STALE_TIME,
    enabled: !!settingsQuery.data && enabled,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.offset + PAGE_SIZE;
      if (nextPage > lastPage.total) {
        return undefined;
      }
      return nextPage;
    },
    onSuccess: (data) => {
      if (data.pages.length === 1) {
        onFirstPageSuccess?.(data.pages[0]);
      }
    },
  });
};
