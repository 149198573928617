import { IconChevronRight, IconGift } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { UnstyledButton } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';
import { useStyles } from './BoostBanner.styles';
import BoostsAvailableModal from './modals/BoostsAvailableModal';

function BoostsAvailable({
  amount,
  isOpen,
  setIsOpen,
}: {
  amount: number;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation('contest');
  const { classes, theme, cx } = useStyles();

  const handleViewAvailableBoosts = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <>
      <UnstyledButton
        className={cx(classes.banner, classes.wrapper, classes.available)}
        onClick={handleViewAvailableBoosts}
      >
        <IconGift stroke={1.5} size={24} color={theme.colors.splash[3]} />
        <div className={classes.content}>
          <Text color={theme.white} variant="title-small">
            {t('quickPick.boost.xBoostsAvailable__hasPlural', { count: amount })}
          </Text>
        </div>
        <IconChevronRight stroke={1.5} size={16} color={theme.colors.gray2[3]} />
      </UnstyledButton>
      <BoostsAvailableModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
}

export default BoostsAvailable;
