import { createStyles } from '@mantine/core';
import Color from 'color';

import Image from '~/domains/common/components/Image/Image';
import silhouetteLight from '~/components/assets/silhouette-light.png';

const useStyles = createStyles((theme, { isDark }: { isDark?: boolean } = {}) => ({
  container: {
    width: theme.other.spacing._40,
    height: theme.other.spacing._40,
    borderRadius: '100em',
    border: `1px solid ${theme.colors.gray[4]}`,
    overflow: 'hidden',
    backgroundColor: isDark ? theme.colors.gray[6] : theme.colors.gray[1],
  },
  image: {
    objectFit: 'contain',
  },
}));

function PlayerImageCircle({ src, alt, isDark }: { src?: string; alt: string; isDark?: boolean }) {
  const { classes, theme } = useStyles({ isDark });

  return (
    <div className={classes.container}>
      <Image
        src={src}
        alt={alt}
        width={theme.other.spacing._40}
        height={theme.other.spacing._40}
        className={classes.image}
        fallbackComponent={
          <Image
            src={silhouetteLight}
            width={theme.other.spacing._40}
            height={theme.other.spacing._40}
            alt={alt}
          />
        }
      />
    </div>
  );
}

export default PlayerImageCircle;
