import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  banner: {
    width: '100%',
    minWidth: '330px',
    padding: '12px 16px',
    justifyContent: 'flex-start',
    background:
      'linear-gradient(90deg, rgba(75, 235, 226, 0.20) 0%, rgba(218, 71, 247, 0.20) 100%), #1A1C1E',
    borderRadius: '4px',
  },
  selected: {
    border: '2px solid',
    borderImage: 'linear-gradient(90deg, #4BEBE2 0%, #DA47F7 100%) 1',
    borderWidth: '4px',
    padding: '5px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
  },
  available: {
    padding: '8px 16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2px',
    flex: '1 0 0',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
  },
  bottomText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  bottomTextWithExpires: {
    justifyContent: 'space-between',
    width: '180px',
  },
  expires: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2px',
    lineHeight: 1,
  },
  termsLink: {
    '&:not(:disabled):hover': {
      textDecoration: 'none',
      opacity: 1,
    },
  },
  container: {
    display: 'flex',
    overflowX: 'auto',
    gap: theme.other.spacing._8,
    marginBottom: -theme.other.spacing._16,
  },
  applyCTA: {
    display: 'flex',
    height: '24px',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    color: theme.colors.gray2[5],
    backgroundColor: theme.colors.gray2[0],
  },
  removeCTA: {
    backgroundColor: 'rgba(241, 243, 245, 0.10)',
    color: theme.white,
  },
}));
