import { createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import Modal from '~/domains/common/components/Modal/Modal/Modal';
import { Button } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';

const CLOSE_BUTTON_HEIGHT = 48;
const useStyles = createStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    textAlign: 'center',
  },
  modal: {
    padding: '0 !important',
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.other.spacing._8,
    zIndex: 1,
    width: '100%',
    height: `${CLOSE_BUTTON_HEIGHT}px`,
  },
  header: {
    padding: theme.other.spacing._8,
    textAlign: 'center',
    borderBottom: `1px solid ${theme.colors.gray2[0]}`,
  },
  body: {
    padding: theme.other.spacing._16,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    gap: theme.other.spacing._16,
  },
  closeButton: {
    padding: '0',
  },
}));

type BoostChangeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onApply: () => void;
};

function BoostChangeModal({ isOpen, onClose, onApply }: BoostChangeModalProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('contest');
  return (
    <Modal
      isOpened={isOpen}
      classNames={{
        drawer: {
          body: classes.modal,
        },
        modal: {
          modal: classes.modal,
        },
      }}
      onClose={onClose}
      withCloseButton={false}
    >
      <div className={classes.body}>
        <div className={classes.header}>
          <Text variant="title-large">{t('quickPick.boost.change')}</Text>
        </div>
        <div className={classes.content}>
          <Text variant="body-large">{t('quickPick.boost.changeCopy')}</Text>
          <Text variant="body-large">{t('quickPick.boost.changeCopy2')}</Text>
        </div>
        <div className={classes.closeButtonContainer}>
          <Button onClick={onClose} fullWidth variant="tertiary-fill">
            <Text variant="label-medium">{t('quickPick.boost.cancel')}</Text>
          </Button>
          <Button onClick={onApply} fullWidth variant="primary-fill">
            <Text variant="label-medium">{t('quickPick.boost.confirm')}</Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default BoostChangeModal;
