import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type {
  PlayerGamesLogTable,
  PlayerGamesLogTableColumn,
} from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

const parseWRorTE = (playerSeasonGames: PlayerSeasonGame[]): PlayerGamesLogTable => {
  const table: Record<string, PlayerGamesLogTableColumn> = {
    recTAR: {
      label: 'REC TAR',
      values: [],
    },
    recs: {
      label: 'RECs',
      values: [],
    },
    recYDS: {
      label: 'REC YDS',
      values: [],
    },
    recYPC: {
      label: 'REC YPC',
      values: [],
    },
    recTD: {
      label: 'REC TD',
      values: [],
    },
    rushATT: {
      label: 'Rush ATT',
      values: [],
    },
    rushYDS: {
      label: 'Rush YDS',
      values: [],
    },
    rushYPC: {
      label: 'Rush YPC',
      values: [],
    },
    rushTD: {
      label: 'Rush TD',
      values: [],
    },
  };

  playerSeasonGames.forEach((playerSeasonGame) => {
    const { playerActivity } = playerSeasonGame;

    table.recTAR.values.push(playerActivity.receiving?.targets ?? '-');
    table.recs.values.push(playerActivity.receiving?.receptions ?? '-');
    table.recYDS.values.push(playerActivity.receiving?.yards ?? '-');
    table.recYPC.values.push(playerActivity.receiving?.avg_yards ?? '-');
    table.recTD.values.push(playerActivity.receiving?.touchdowns ?? '-');
    table.rushATT.values.push(playerActivity.rushing?.attempts ?? '-');
    table.rushYDS.values.push(playerActivity.rushing?.yards ?? '-');
    table.rushYPC.values.push(playerActivity.rushing?.avg_yards ?? '-');
    table.rushTD.values.push(playerActivity.rushing?.touchdowns ?? '-');
  });

  return Object.entries(table).map(([_, value]) => value);
};

export default parseWRorTE;
