import { createStyles } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';

import { Button } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';

import { useCallback } from 'react';
import { BarChart } from '~/domains/design-system/components/charts/BarChart/BarChart';
import type { PropPickProps } from '../../types/propPickProps.type';

interface OtherPropsProps {
  handlePick: (pick: PropPickProps) => void;
  propData: PropPickProps[];
  selectedPick: PropPickProps;
}

const useStyles = createStyles((theme) => ({
  container: {
    overflowY: 'auto',
  },
  title: {
    fontSize: '14px',
    fontWeight: 900,
    marginBottom: '16px',
  },
  prop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px dashed ${theme.colors.gray[4]}`,
    paddingTop: theme.other.spacing._8,
    paddingBottom: theme.other.spacing._8,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  propData: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  lineLabel: {
    fontFamily: theme.other.fontFamily.hubotSans,
    fontStretch: '75%',
    color: theme.colors.gray2[7],
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.other.spacing._4,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 4px 8px 4px',
    gap: '4px',
    width: '75px',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.other.spacing._24,
    marginTop: theme.other.spacing._16,
  },
  chartInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    alignItems: 'center',
  },
  chartWrapper: {
    width: '80px',
    height: '36px',
    paddingLeft: theme.other.spacing._8,
    paddingRight: theme.other.spacing._8,
  },
  rightSide: {
    display: 'flex',
    gap: theme.other.spacing._32,
    alignItems: 'center',
  },
}));

function OtherProps({ handlePick, propData, selectedPick }: OtherPropsProps) {
  const { t } = useTranslation('contest');
  const { classes } = useStyles();

  const onClick = useCallback(
    (pick: PropPickProps, direction: 'over' | 'under') => () => {
      handlePick({ ...pick, direction });
    },
    [handlePick]
  );

  /* eslint-disable react/prop-types */
  if (!propData?.length) {
    return (
      <>
        <Text className={classes.title}>{t('quickPick.playerCard.otherPicks')}</Text>
        <div className={classes.emptyState}>
          <Text fw={900} size={12} color="gray2.3">
            {t('quickPick.playerCard.noOtherPicks')}
          </Text>
          <div>
            <svg
              width="174"
              height="208"
              viewBox="0 0 174 208"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_439_19095)">
                <rect x="0.5" width="173" height="208" rx="8" fill="white" />
                <rect x="0.5" width="173" height="98" fill="#F1F3F5" />
                <rect x="8.5" y="168" width="74.5" height="32" rx="4" fill="#F1F3F5" />
                <rect x="91" y="168" width="74.5" height="32" rx="4" fill="#F1F3F5" />
                <g clipPath="url(#clip1_439_19095)">
                  <rect
                    width="171"
                    height="112"
                    transform="translate(1.5)"
                    fill="url(#paint0_linear_439_19095)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M47.3446 15.8541C49.6806 14.5955 54.0831 13.4868 58.0363 13.1272C66.3022 12.393 73.4001 14.8502 78.2818 20.1693C83.9272 26.2825 86.0386 34.1037 84.6609 43.753C84.2566 46.6447 83.2683 50.5554 82.5046 52.2934C82.3998 52.5332 82.1901 53.3422 82.0554 54.0914C81.0221 59.5753 77.9224 65.8982 74.3735 69.7639L73.5948 70.618V73.0303C73.5948 74.3937 73.6547 75.4276 73.7296 75.3826C73.8793 75.2777 74.8377 75.982 76.6945 77.5702C78.2818 78.9337 80.5579 80.3271 84.2267 82.1701C87.1467 83.6534 89.2581 84.5074 94.8585 86.5302C103.978 89.8265 108.036 91.8343 110.537 94.3215C111.675 95.4452 112.229 96.2543 112.813 97.6627C113.547 99.4425 113.943 110.215 114 112H59.9999H6C6.04553 110.833 6.24386 100.673 6.58408 99.4907C7.24295 97.2132 8.02163 95.7898 9.33938 94.4713C12.0348 91.7743 15.8383 89.8864 25.377 86.4553C30.4234 84.6423 32.9541 83.6084 35.2751 82.4398C39.3632 80.3571 42.2383 78.3493 43.7956 76.4914C44.3047 75.8771 44.8588 75.2628 45.0085 75.1129C45.2631 74.8882 45.2931 74.6185 45.2931 72.4609V70.0486L44.3946 68.9698C40.9205 64.8344 38.1952 58.8111 37.2218 53.1924C37.0571 52.2035 36.6827 50.1059 36.3982 48.5476C34.5713 38.5987 34.0922 30.7924 35.0505 26.9717C35.6345 24.6643 37.2967 22.3119 39.3781 20.8136C40.4713 20.0345 42.8073 18.9107 43.8405 18.641C44.3646 18.5212 44.6042 18.3563 44.769 18.0567C45.0834 17.4573 46.3263 16.3935 47.3446 15.8541Z"
                    fill="#D1D1D1"
                  />
                  <rect x="134.5" y="12" width="30" height="14" rx="4" fill="#D1D1D1" />
                  <rect x="120.5" y="34" width="44" height="8" rx="2" fill="#D1D1D1" />
                </g>
                <rect x="9.5" y="122" width="87" height="14" rx="4" fill="#D1D1D1" />
                <rect x="9.5" y="144" width="138" height="8" rx="2" fill="#F1F3F5" />
              </g>
              <rect
                x="1.5"
                y="1"
                width="171"
                height="206"
                rx="7"
                stroke="#F1F3F5"
                strokeWidth="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_439_19095"
                  x1="85.5"
                  y1="0"
                  x2="85.5"
                  y2="112"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" />
                  <stop offset="1" stopColor="#F1F3F5" />
                </linearGradient>
                <clipPath id="clip0_439_19095">
                  <rect x="0.5" width="173" height="208" rx="8" fill="white" />
                </clipPath>
                <clipPath id="clip1_439_19095">
                  <path
                    d="M1.5 8C1.5 3.58172 5.08172 0 9.5 0H164.5C168.918 0 172.5 3.58172 172.5 8V112H1.5V8Z"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </>
    );
  }

  // eslint-disable-next-line react/prop-types
  return (
    <>
      <Text className={classes.title}>{t('quickPick.playerCard.otherPicks')}</Text>
      <div className={classes.container}>
        {/* eslint-disable-next-line react/prop-types */}
        {propData?.map((prop) => {
          const isSelected = selectedPick?.id === prop.id;

          return (
            <div key={prop?.id} className={classes.prop}>
              <div className={classes.propData}>
                <Text fw={900} size={20}>
                  {prop.line.value}
                </Text>
                <Text className={classes.lineLabel} transform="uppercase" size={14}>
                  {prop.line.label}
                </Text>
              </div>
              <div className={classes.rightSide}>
                {prop.chartProps && (
                  <div className={classes.chartInfoWrapper}>
                    <div className={classes.chartWrapper}>
                      <BarChart {...prop.chartProps} variant="simple" invertColors height="100%" />
                    </div>
                    <Text size={2} color="gray2.3">
                      {t('quickPick.playerCard.lastXAvgShort', {
                        count: prop.chartProps?.tickCount,
                      })}
                      <Text size={2} color="gray2.7" fw={900}>
                        {prop.chartProps?.lastXAvg}
                      </Text>
                    </Text>
                  </div>
                )}
                <div className={classes.buttonWrapper}>
                  <Button
                    className={classes.button}
                    variant={
                      isSelected && selectedPick?.direction === 'over'
                        ? 'primary-fill'
                        : 'tertiary-fill'
                    }
                    onClick={onClick(prop, 'over')}
                    size="small"
                  >
                    <IconArrowUp size={16} />
                    {t('quickPick.board.playerCard.more')}
                  </Button>
                  <Button
                    className={classes.button}
                    variant={
                      isSelected && selectedPick?.direction === 'under'
                        ? 'primary-fill'
                        : 'tertiary-fill'
                    }
                    onClick={onClick(prop, 'under')}
                    size="small"
                  >
                    <IconArrowDown size={16} />
                    {t('quickPick.board.playerCard.less')}
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export { OtherProps };
