import { createStyles, LoadingOverlay } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import type { UserDirection } from '~/domains/contest/domains/services/interfaces/propsService/api';
import { UnstyledButton } from '~/domains/design-system/Button';
import { Drawer } from '~/domains/design-system/components/Drawer/Drawer';
import { IconAlertTriangle } from '~/domains/design-system/icons/IconAlertTriangle';
import IconChevronDown2 from '~/domains/design-system/icons/IconChevronDown2';
import { textStyles } from '~/domains/design-system/Text';

import type { PropPickProps } from '../../../types/propPickProps.type';

import PicksDrawerActionsPanel from './PicksDrawerActionsPanel';
import PicksDrawerPick from './PicksDrawerPick/PicksDrawerPick';

const useStyles = createStyles((theme) => ({
  drawerOverlay: {
    zIndex: 100,
  },
  drawerContent: {
    borderRadius: `${theme.other.spacing._8}px ${theme.other.spacing._8}px 0 0`,
    height: 'calc(100svh - 100px)',
    zIndex: 100,
    maxWidth: theme.other.layout.maxWidth - theme.other.spacing._8,

    [theme.fn.smallerThan('md')]: {
      height: 'calc(100svh - 20px)',
    },

    '&:focus': {
      outline: 'none',
    },
  },
  drawerInnerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: `${theme.other.spacing._8}px ${theme.other.spacing._8}px 0 0`,
    padding: 0,
  },
  drawerInnerInnerContent: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden',

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
    },
  },
  list: {
    flexGrow: 1,
    overflow: 'auto',
  },
  listItem: {
    borderBottom: `1px solid ${theme.colors.gray2[0]}`,
  },
  header: {
    height: theme.other.spacing._48,
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.other.spacing._16}px`,
    gap: theme.other.spacing._8,
    borderBottom: `1px solid ${theme.colors.gray2[0]}`,
  },
  title: {
    ...textStyles['subhead-small'],
    color: theme.colors.gray2[6],
    flexGrow: 1,
  },
  arrowButton: {
    height: theme.other.spacing._24,
    width: theme.other.spacing._24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',

      '&:hover': {
        opacity: 0.5,
      },
    },
  },
  closeButton: {
    ...textStyles['title-large'],
    color: theme.colors.gray2[6],

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',

      '&:hover': {
        opacity: 0.5,
      },
    },
  },
  actionPanel: {
    width: 390,
    height: '100%',

    [theme.fn.smallerThan('md')]: {
      width: '100%',
      height: 'auto',
    },
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing._8,
    backgroundColor: theme.colors.orange[3],
    color: theme.white,
    padding: `${theme.other.spacing._8}px ${theme.other.spacing._16}px`,
  },
  errorMessageContent: {
    ...textStyles['title-small'],
  },
}));

function PicksDrawer({
  balance,
  entryAmounts,
  errorMessage,
  isBalanceError,
  isBalanceHidden,
  isBalanceLoading,
  isLoading,
  isOpen,
  isSubmitDisabled,
  picks,
  potentialPayoutAmount,
  selectedEntryAmount,
  submitLabel,
  onClose,
  onEntryAmountChange,
  onPickChange,
  onPickRemove,
  onSubmit,
  multiplier,
  isSplitEntryEligible,
  isBoostsAvailableModalOpen,
  setIsBoostsAvailableModalOpen,
}: {
  balance: number;
  entryAmounts: number[];
  errorMessage?: string;
  isBalanceError?: boolean;
  isBalanceHidden?: boolean;
  isBalanceLoading?: boolean;
  isOpen: boolean;
  isLoading?: boolean;
  isSubmitDisabled?: boolean;
  picks: PropPickProps[];
  potentialPayoutAmount: number;
  selectedEntryAmount: number;
  submitLabel: string;
  onClose: () => void;
  onEntryAmountChange: (amount: number) => void;
  onPickChange: (id: string, direction: UserDirection) => void;
  onPickRemove: (id: string) => void;
  onSubmit: () => void;
  multiplier: number;
  isSplitEntryEligible: boolean;
  isBoostsAvailableModalOpen: boolean;
  setIsBoostsAvailableModalOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation('contest');
  const { classes, theme } = useStyles();

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      isModal={false}
      classes={{
        content: classes.drawerContent,
        innerContent: classes.drawerInnerContent,
        overlay: classes.drawerOverlay,
      }}
      contentProps={{ className: classes.drawerInnerInnerContent }}
      elements={{
        header: (
          <div className={classes.header}>
            <UnstyledButton className={classes.arrowButton} onClick={onClose}>
              <IconChevronDown2 />
            </UnstyledButton>
            <div className={classes.title}>
              {t('quickPick.board.picksDrawer.title', { count: picks.length })}
            </div>
            <UnstyledButton className={classes.closeButton} onClick={onClose}>
              {t('quickPick.board.picksDrawer.close')}
            </UnstyledButton>
          </div>
        ),
      }}
    >
      <LoadingOverlay visible={isLoading} />
      <div className={classes.container}>
        <div className={classes.list}>
          {errorMessage && (
            <div className={classes.errorMessage}>
              <IconAlertTriangle size={24} color={theme.white} />
              <div className={classes.errorMessageContent}>{errorMessage}</div>
            </div>
          )}
          {picks.map((pick) => (
            <div className={classes.listItem} key={pick.id}>
              <PicksDrawerPick {...pick} onPickChange={onPickChange} onPickRemove={onPickRemove} />
            </div>
          ))}
        </div>
        <div className={classes.actionPanel}>
          <PicksDrawerActionsPanel
            balance={balance}
            entryAmounts={entryAmounts}
            multiplier={multiplier}
            isSplitEntryEligible={isSplitEntryEligible}
            isBalanceError={isBalanceError}
            isBalanceHidden={isBalanceHidden}
            isBalanceLoading={isBalanceLoading}
            isSubmitDisabled={isSubmitDisabled}
            potentialPayoutAmount={potentialPayoutAmount}
            selectedEntryAmount={selectedEntryAmount}
            submitLabel={submitLabel}
            onEntryAmountChange={onEntryAmountChange}
            onSubmit={onSubmit}
            isBoostsAvailableModalOpen={isBoostsAvailableModalOpen}
            setIsBoostsAvailableModalOpen={setIsBoostsAvailableModalOpen}
            picksTotal={picks.length}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default PicksDrawer;
