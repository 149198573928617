import { useInfiniteQuery } from '@tanstack/react-query';
import { getBoosts } from '../../services/props.service.api';
import QuickPickQueryKeys, { SHORT_CACHE_TIME } from '../constants/query';

type UseQuickPicksBoostsInfiniteProps = {
  enabled?: boolean;
  refetchInterval?: number;
};

const PAGE_SIZE = 8;

const useQuickPicksBoosts = ({ enabled, refetchInterval }: UseQuickPicksBoostsInfiniteProps) =>
  useInfiniteQuery({
    queryKey: QuickPickQueryKeys.QUICK_PICKS_BOOSTS(),
    queryFn: ({ pageParam = 0 }) =>
      getBoosts({
        limit: PAGE_SIZE,
        offset: pageParam,
      }),
    enabled,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.offset + PAGE_SIZE;
      if (nextPage > lastPage.total) {
        return undefined;
      }
      return nextPage;
    },
    cacheTime: SHORT_CACHE_TIME, // short cache as this gets pusher updates
    ...(refetchInterval ? { refetchInterval } : {}),
  });

export default useQuickPicksBoosts;
