import type { QuickPicksGroupConfigOptions } from '../../services/interfaces/props-settings.interface';
import { useBoardContext } from '../context/BoardContext/BoardContext';
import { useQuickPicksSettings } from './useQuickPicksSettings';

export const useQuickPicksGroupConfig = (): QuickPicksGroupConfigOptions => {
  const settingsQuery = useQuickPicksSettings();
  const { picks } = useBoardContext();
  const defaultConfig = {
    multiplier: 0,
    entry_fee_options: [],
    entry_options: [],
    slip_length: 0,
    group_size: 0,
  };

  const getP2PConfigBySlipLength = (slipLength: number) =>
    settingsQuery.data?.peer_to_peer_config.options.find(
      (option) => option.slip_length === slipLength
    ) ?? defaultConfig;

  const config = getP2PConfigBySlipLength(picks?.value?.length);

  return config;
};
