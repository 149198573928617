/* eslint-disable @typescript-eslint/naming-convention */
import { reportQuickPicksPicksView } from '../../../analyticsEvents';
import { MIN_PICK_COUNT } from '../../../constants/groupConfig';
import { useBoardContext } from '../../../context/BoardContext/BoardContext';
import { useQuickPicksGroupConfig } from '../../../hooks/useQuickPicksGroupConfig';
import { useQuickPicksSettings } from '../../../hooks/useQuickPicksSettings';

import PicksPanel from './PicksPanel';

function ConnectedPicksPanel() {
  const { filters } = useBoardContext();
  const settingsQuery = useQuickPicksSettings();
  const groupConfig = useQuickPicksGroupConfig();

  const { picks, picksDrawer, error, selectedBoost } = useBoardContext();

  if (!settingsQuery.data) {
    return null;
  }

  const { options } = settingsQuery.data.peer_to_peer_config;

  const maxPicks = options[options.length - 1].slip_length;

  return (
    <PicksPanel
      boost={selectedBoost}
      picks={picks.value}
      minPicks={MIN_PICK_COUNT}
      maxPicks={maxPicks}
      payoutMultiplier={groupConfig?.multiplier}
      errorMessage={error.error}
      onViewEntryClick={() => {
        picksDrawer.toggle();
        filters.set('playerCardId', null);
        reportQuickPicksPicksView({
          numberOfPicks: picks.value.length,
        });
      }}
    />
  );
}

export default ConnectedPicksPanel;
