function IconAlertTriangle({ size = 41, color = '#909296' }: { size?: number; color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 41 40"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
        d="M20.5 16.668V20m0 6.666v.017m-11.667 6.65h23.334a3.333 3.333 0 0 0 3.066-4.583L23.4 8.334a3.333 3.333 0 0 0-5.833 0L5.733 28.751a3.333 3.333 0 0 0 2.917 4.583"
      />
    </svg>
  );
}
IconAlertTriangle.displayName = 'IconAlertTriangle';

export { IconAlertTriangle };
