const enum ConfirmationModalType {
  SLIP_CANCELLATION = 'SLIP_CANCELLATION',
  ENTRY_LIMIT_REACHED = 'ENTRY_LIMIT_REACHED',
}

type ConfirmationModalPayload = {
  type?: ConfirmationModalType;
  onClose?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  remainingTime?: string;
  isOnConfirmLoading?: boolean;
};

type ConfirmationModalContextValue = {
  isOpen: boolean;
  payload: ConfirmationModalPayload;
  setIsOpen: (
    props: { isOpen: false } | { isOpen: true; payload: ConfirmationModalPayload }
  ) => void;
  setRemainingTime?: (time?: string) => void;
  setOnConfirmLoading?: (isLoading?: boolean) => void;
};

export type { ConfirmationModalContextValue, ConfirmationModalPayload };

export { ConfirmationModalType };
