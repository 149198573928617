import { useIsMutating } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { KYCContext } from '~/components/providers/KYCProvider';
import { UserContext } from '~/components/providers/UserProvider';
import { Routes } from '~/domains/common/constants/routes';
import { RootModalsContext } from '~/domains/common/context/RootModalsContext/RootModalsContext';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';

import QuickPickQueryKeys from '../../../constants/query';
import { useBoardContext } from '../../../context/BoardContext/BoardContext';
import { useQuickPickLocationAllowed } from '../../../hooks/useQuickPickLocationAllowed';
import useQuickPicksValidateBoost from '../../../hooks/useValidateBoost';

type TPicksEntryValidationError =
  | 'LOCATION_INVALID'
  | 'KYC_INVALID'
  | 'BALANCE_LOADING'
  | 'BALANCE_ERROR'
  | 'BALANCE_INSUFFICIENT'
  | 'NOT_ENOUGHT_PICKS'
  | 'MUST_PICK_AT_LEAST_TWO_TEAMS'
  | 'INVALID_BOOST_VALIDATION';

function useCreateEntryButton({
  selectedEntryAmount,
  minPicks,
  onSubmit,
}: {
  selectedEntryAmount: number;
  minPicks: number;
  onSubmit: () => void;
}) {
  const { t } = useTranslation('contest');

  const { isUserSignedIn } = useContext(UserContext);
  const { picks, picksDrawer, selectedBoostId } = useBoardContext();
  const [boostValidationError, setBoostValidationError] = useState<string | null>(null);
  const walletBalanceQuery = useWalletBalance();
  const isLocationAllowed = useQuickPickLocationAllowed();
  const validateBoost = useQuickPicksValidateBoost();

  const { KYCStatus } = useContext(KYCContext);
  const router = useRouter();

  const isSlipMutationLoading =
    useIsMutating({ mutationKey: QuickPickQueryKeys.CREATE_SLIP() }) > 0;

  const { openModal } = useContext(RootModalsContext);

  const previousSelectedBoostId = useRef(selectedBoostId);
  useEffect(() => {
    const validateSelectedBoost = async () => {
      if (selectedBoostId) {
        try {
          await validateBoost.mutateAsync({
            boostId: selectedBoostId,
            payload: {
              prop_ids: picks.value.map((pick) => pick.id),
              amount: selectedEntryAmount,
            },
          });

          setBoostValidationError(null);
        } catch (error) {
          setBoostValidationError(error.response?.data.message);
        }
      }
    };

    // if the user changes the boost, we need to reset the error
    if (selectedBoostId !== previousSelectedBoostId.current) {
      setBoostValidationError(null);
    }
    previousSelectedBoostId.current = selectedBoostId;

    validateSelectedBoost();
  }, [selectedBoostId, selectedEntryAmount, picks.value]);
  const error: TPicksEntryValidationError = useMemo(() => {
    if (isUserSignedIn) {
      if (!isLocationAllowed) {
        return 'LOCATION_INVALID';
      }

      if (KYCStatus !== 'accept') {
        return 'KYC_INVALID';
      }

      if (walletBalanceQuery.isLoading) {
        return 'BALANCE_LOADING';
      }

      const balance = walletBalanceQuery.data?.availableBalance;
      if (typeof balance !== 'number') {
        return 'BALANCE_ERROR';
      }

      if (balance < selectedEntryAmount) {
        return 'BALANCE_INSUFFICIENT';
      }
    }

    if (minPicks > picks.value.length) {
      return 'NOT_ENOUGHT_PICKS';
    }

    const teams = picks.value.map((pick) => pick.playerTeamId);
    const uniqueTeams = new Set(teams);
    if (uniqueTeams.size < 2) {
      return 'MUST_PICK_AT_LEAST_TWO_TEAMS';
    }

    if (selectedBoostId && boostValidationError) {
      return 'INVALID_BOOST_VALIDATION';
    }

    return null;
  }, [
    KYCStatus,
    isUserSignedIn,
    minPicks,
    picks.value,
    selectedEntryAmount,
    selectedBoostId,
    boostValidationError,
    walletBalanceQuery.data?.availableBalance,
    walletBalanceQuery.isLoading,
  ]);

  const isSubmitDisabled = useMemo(
    () =>
      [
        'LOCATION_INVALID',
        'BALANCE_LOADING',
        'BALANCE_ERROR',
        'NOT_ENOUGHT_PICKS',
        'MUST_PICK_AT_LEAST_TWO_TEAMS',
        'INVALID_BOOST_VALIDATION',
      ].includes(error) || isSlipMutationLoading,
    [isSlipMutationLoading, error]
  );

  const submitLabel = useMemo(() => {
    switch (error) {
      case 'BALANCE_ERROR': {
        return t('quickPick.board.picksDrawer.actionsPanel.submitLabel.balanceError');
      }
      case 'BALANCE_INSUFFICIENT': {
        return t('quickPick.board.picksDrawer.actionsPanel.submitLabel.balanceInsufficient');
      }
      case 'KYC_INVALID': {
        return t('quickPick.board.picksDrawer.actionsPanel.submitLabel.kycInvalid');
      }
      case 'LOCATION_INVALID': {
        return t('quickPick.board.picksDrawer.actionsPanel.submitLabel.locationInvalid');
      }
      case 'INVALID_BOOST_VALIDATION': {
        return t('quickPick.board.picksDrawer.actionsPanel.submitLabel.boostInvalid');
      }
      default: {
        if (!isUserSignedIn) {
          return t('quickPick.board.picksDrawer.actionsPanel.submitLabel.signInToEnter');
        }
        return isSlipMutationLoading
          ? t('quickPick.board.picksDrawer.actionsPanel.submitLabel.submitting')
          : t('quickPick.board.picksDrawer.actionsPanel.submitLabel.default');
      }
    }
  }, [isSlipMutationLoading, error, isUserSignedIn, t]);

  const handleClick = useCallback(() => {
    if (isSubmitDisabled) {
      return;
    }

    if (error === 'KYC_INVALID') {
      router.push(
        Routes.userVerification({
          redirectTo: `${window.location.pathname}${window.location.search}`,
        })
      );
      return;
    }

    if (error === 'BALANCE_INSUFFICIENT') {
      picksDrawer.close();
      openModal({ type: 'DepositModal' });
      return;
    }

    onSubmit();
  }, [error, isSubmitDisabled, onSubmit, openModal, picksDrawer, router]);

  return {
    isSubmitDisabled,
    submitLabel,
    error,
    onClick: handleClick,
    boostValidationError,
  };
}

export default useCreateEntryButton;
