import { useQuery } from '@tanstack/react-query';

import { STALE_TIME } from '~/domains/contest/constants/query';

import { getLocationAllowed } from '../../services/props.service.api';
import QuickPickQueryKeys from '../constants/query';

export const useQuickPickLocationAllowed = () => {
  const { data } = useQuery({
    queryKey: QuickPickQueryKeys.QUICK_PICKS_LOCATION_ALLOWED(),
    queryFn: () => getLocationAllowed(),
    staleTime: STALE_TIME,
  });

  return data?.is_allowed_in_location ?? true;
};
