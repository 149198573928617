import { createStyles } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';

import { textStyles } from '~/domains/design-system/Text';

import PlayerImageCircle from '../../PlayerImageCircle';
import GameTeamsLabel from '../../GameTeamsLabel';
import type { PropPickProps } from '../../../../types/propPickProps.type';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing._8,
    padding: theme.other.spacing._16,
    background: theme.white,
    borderRadius: theme.other.spacing._8,
  },
  image: {
    flexShrink: 0,
  },
  pickInfo: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  titleAndPosition: {
    display: 'flex',
    alignItems: 'baseline',
  },
  title: {
    ...textStyles['headline-medium'],
    color: theme.colors.gray2[6],
  },
  position: {
    ...textStyles['subhead-small'],
    color: theme.colors.gray2[3],
  },
  subtitle: {
    ...textStyles['body-medium'],
    color: theme.colors.gray2[3],

    '& strong': {
      ...textStyles['subhead-small'],
    },
  },
  lineContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing._4,
  },
  line: {
    display: 'flex',
    gap: theme.other.spacing._8,
    alignItems: 'center',
    overflow: 'hidden',
  },
  lineValueContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing._4,
    overflow: 'hidden',
    minWidth: 'fit-content',
  },
  lineValue: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: theme.other.fontFamily.hubotSans,
    fontStretch: '75%',
    fontWeight: 900,
    color: theme.colors.gray2[6],
    flexShrink: 0,
  },
  lineValueOld: {
    position: 'relative',
    flexShrink: 0,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 'calc(50% - 2px)',
      left: 0,
      right: 0,
      height: '2px',
      background: theme.colors.yellow[3],
    },
  },
  lineLabel: {
    fontSize: '12px',
    fontFamily: theme.other.fontFamily.hubotSans,
    textAlign: 'right',
    flexShrink: 1,
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontStretch: '75%',
    overflow: 'hidden',
    color: theme.colors.gray2[6],
  },
  lineRemovedLabel: {
    ...textStyles['title-small'],
    color: theme.colors.orange[3],
    flexShrink: 0,
  },
  arrowIcon: {
    flexShrink: 0,
  },
}));

export type EntryPicksChangedAlertPickProps = {
  oldProp: PropPickProps;
  newProp?: PropPickProps;
};

function EntryPicksChangedAlertPick({
  oldProp: { awayTeam, homeTeam, line, playerImage, playerName, playerTeamId, position, startDate },
  newProp,
}: EntryPicksChangedAlertPickProps) {
  const { classes, cx, theme } = useStyles();
  const { t } = useTranslation('contest');

  return (
    <div className={classes.container}>
      <div className={classes.image}>
        <PlayerImageCircle src={playerImage} alt="Name" />
      </div>
      <div className={classes.pickInfo}>
        <div className={classes.titleAndPosition}>
          <div className={classes.title}>{playerName}</div>
          {position && (
            <div className={classes.position}>
              &nbsp;•&nbsp;
              {position}
            </div>
          )}
        </div>
        <div className={classes.subtitle}>
          <GameTeamsLabel homeTeam={homeTeam} awayTeam={awayTeam} playerTeamId={playerTeamId} />
          {' • '}
          {startDate}
        </div>
        {(() => {
          if (!newProp) {
            return (
              <div className={classes.lineContainer}>
                <div className={classes.line}>
                  <div className={cx(classes.lineValue, classes.lineValueOld)}>{line.value}</div>
                  <div className={classes.lineLabel}>{line.label}</div>
                </div>
                <IconArrowRight
                  size={16}
                  color={theme.colors.gray2[3]}
                  className={classes.arrowIcon}
                />
                <div className={classes.lineRemovedLabel}>
                  {t('quickPick.board.entryChangedAlert.removed')}
                </div>
              </div>
            );
          }

          return (
            <div className={classes.line}>
              <div className={classes.lineValueContainer}>
                <div className={cx(classes.lineValue, classes.lineValueOld)}>{line.value}</div>
                <IconArrowRight
                  size={16}
                  color={theme.colors.gray2[3]}
                  className={classes.arrowIcon}
                />
                <div className={classes.lineValue}>{newProp.line.value}</div>
              </div>
              <div className={classes.lineLabel}>{line.label}</div>
            </div>
          );
        })()}
      </div>
    </div>
  );
}

export default EntryPicksChangedAlertPick;
