import useTranslation from 'next-translate/useTranslation';

import { SegmentedControl } from '~/domains/common/components/SegmentedControl/SegmentedControl';

interface TabsProps {
  setActiveTab: (tab: string) => void;
}

function Tabs({ setActiveTab }: TabsProps) {
  const { t } = useTranslation('contest');

  return (
    <SegmentedControl
      data={[
        { label: t('quickPick.playerCard.picks'), value: 'props' },
        { label: t('quickPick.playerCard.gameLog'), value: 'stats' },
      ]}
      onChange={setActiveTab}
      fullWidth
      mb="lg"
    />
  );
}

export { Tabs };
