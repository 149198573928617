import { useContext } from 'react';

import { ConfirmationModalContext } from '../ConfirmationModalContext';
import { ConfirmationModalType } from '../types';

function useEntryLimitReachedAlert() {
  const confirmationModal = useContext(ConfirmationModalContext);

  const onHandleEntryLimitAlertShow = () => {
    confirmationModal.setIsOpen({
      isOpen: true,
      payload: {
        type: ConfirmationModalType.ENTRY_LIMIT_REACHED,
        onClose: () => {
          confirmationModal.setIsOpen({ isOpen: false });
        },
      },
    });
  };

  return onHandleEntryLimitAlertShow;
}

export default useEntryLimitReachedAlert;
