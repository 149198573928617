import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type {
  PlayerGamesLogTable,
  PlayerGamesLogTableColumn,
} from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

const parseQB = (playerSeasonGames: PlayerSeasonGame[]): PlayerGamesLogTable => {
  const table: Record<string, PlayerGamesLogTableColumn> = {
    passATT: {
      label: 'Pass ATT',
      values: [],
    },
    passCMP: {
      label: 'Pass CMP',
      values: [],
    },
    passYDS: {
      label: 'Pass YDS',
      values: [],
    },
    passTD: {
      label: 'Pass TD',
      values: [],
    },
    passINT: {
      label: 'Pass INT',
      values: [],
    },
    rushATT: {
      label: 'Rush ATT',
      values: [],
    },
    rushYDS: {
      label: 'Rush YDS',
      values: [],
    },
    rushTD: {
      label: 'Rush TD',
      values: [],
    },
  };

  playerSeasonGames.forEach((playerSeasonGame) => {
    const { playerActivity } = playerSeasonGame;
    table.passATT.values.push(playerActivity.passing?.attempts ?? '-');
    table.passCMP.values.push(playerActivity.passing?.completions ?? '-');
    table.passYDS.values.push(playerActivity.passing?.yards ?? '-');
    table.passTD.values.push(playerActivity.passing?.touchdowns ?? '-');
    table.passINT.values.push(playerActivity.passing?.interceptions ?? '-');
    table.rushATT.values.push(playerActivity.rushing?.attempts ?? '-');
    table.rushYDS.values.push(playerActivity.rushing?.yards ?? '-');
    table.rushTD.values.push(playerActivity.rushing?.touchdowns ?? '-');
  });

  return Object.entries(table).map(([_, value]) => value);
};

export default parseQB;
