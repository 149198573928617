import { useTheme } from '@emotion/react';
import { memo } from 'react';
import {
  Bar,
  CartesianGrid,
  Cell,
  LabelList,
  BarChart as RechartsBarChart,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import useBarChartLayout from './useBarChartLayout';

export type BarChartProps = {
  chartData: { label: string; value: number; secondaryLabel?: string }[];
  referenceLineValue?: number;
  variant?: 'default' | 'simple';
  invertColors?: boolean;
  ticks?: number[];
  tickCount?: number;
  height?: number | string;
  width?: number | string;
};

const arePropsEqual = (prevProps: BarChartProps, nextProps: BarChartProps) =>
  prevProps.referenceLineValue === nextProps.referenceLineValue;

const BarChart = memo(
  ({
    chartData,
    ticks,
    tickCount,
    referenceLineValue,
    variant = 'default',
    invertColors = false,
    height = 220,
    width = '100%',
  }: BarChartProps) => {
    const theme = useTheme();
    const { getLabelColor, layoutConfig } = useBarChartLayout({
      referenceLineValue,
      invertColors,
      variant,
    });

    return (
      <ResponsiveContainer width={width} height={height}>
        <RechartsBarChart
          data={chartData}
          margin={layoutConfig.barChartMargin}
          barSize={layoutConfig.barSize}
        >
          <CartesianGrid vertical={false} horizontal={layoutConfig.showHorizontalGrid} />
          <XAxis
            hide={!layoutConfig.showXAxis}
            dataKey="label"
            tickLine={false}
            tickMargin={12}
            tick={{
              fill: theme.colors.gray2[5],
              fontSize: 12,
              fontWeight: 600,
            }}
            height={theme.other.spacing._16}
          />
          <XAxis
            hide={!layoutConfig.showXAxis}
            dataKey="secondaryLabel"
            tickLine={false}
            tickMargin={12}
            tick={{
              fill: theme.colors.gray2[3],
              fontSize: 10,
            }}
            axisLine={false}
            interval={0}
            xAxisId="quarter"
            type="category"
            height={theme.other.spacing._24}
          />
          <YAxis
            padding={layoutConfig.yAxisPadding}
            hide={!layoutConfig.showYAxis}
            type="number"
            tickLine={false}
            tickMargin={12}
            tick={{
              fill: theme.colors.gray2[3],
              fontSize: 10,
              fontFamily: theme.other.fontFamily.hubotSans,
            }}
            tickCount={tickCount}
            ticks={ticks}
          />
          <Bar
            dataKey={({ value }) => (value > 0 ? value : 0)} // only show bars for positive values
            isAnimationActive={layoutConfig.isAnimationActive}
            label={(data) => {
              if (data.value === 0) {
                return null;
              }
              const { borderColor } = getLabelColor(data.value);
              return (
                <Rectangle
                  {...data}
                  height={layoutConfig.barTopHeight}
                  width={data.width - 1}
                  x={data.x + 0.5}
                  fill={borderColor}
                  stroke={borderColor}
                />
              );
            }}
          >
            {chartData.map((entry, index) => {
              const { barColor } = getLabelColor(entry.value);
              // eslint-disable-next-line react/no-array-index-key
              return <Cell key={`cell-${index}`} fill={barColor} />;
            })}
            {layoutConfig.showLabelList && (
              <LabelList
                dataKey="value"
                fill={theme.colors.gray2[4]}
                fontSize={10}
                fontFamily={theme.other.fontFamily.hubotSans}
                fontWeight={900}
                position="top"
                offset={10}
              />
            )}
          </Bar>
          {referenceLineValue && (
            <ReferenceLine
              y={referenceLineValue}
              strokeDasharray="3 3"
              stroke={theme.colors.gray2[3]}
            />
          )}
        </RechartsBarChart>
      </ResponsiveContainer>
    );
  },
  arePropsEqual
);

BarChart.displayName = 'BarChart';
export { BarChart };
