/* eslint-disable @typescript-eslint/naming-convention */
import { useIsMutating } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import type { ComponentProps } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import type { UserDirection } from '~/domains/contest/domains/services/interfaces/propsService/api';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';

import {
  reportQuickPicksPickEdit,
  reportQuickPicksPickRemove,
  reportQuickPicksPicksChangeEntryFee,
  reportQuickPicksPicksClose,
} from '../../../analyticsEvents';
import QuickPickQueryKeys from '../../../constants/query';
import { useBoardContext } from '../../../context/BoardContext/BoardContext';
import { useQuickPicksSettings } from '../../../hooks/useQuickPicksSettings';

import { MIN_PICK_COUNT } from '../../../constants/groupConfig';
import { useQuickPicksGroupConfig } from '../../../hooks/useQuickPicksGroupConfig';
import type PicksDrawer from './PicksDrawer';
import useCreateEntryButton from './useCreateEntryButton';
import useEntrySubmit from './useEntrySubmit';

function usePicksDrawer() {
  const { t } = useTranslation('contest');
  const { isUserSignedIn } = useContext(UserContext);
  const isSlipMutationLoading =
    useIsMutating({ mutationKey: QuickPickQueryKeys.CREATE_SLIP() }) > 0;

  const walletBalanceQuery = useWalletBalance();

  const settingsQuery = useQuickPicksSettings();
  const { picks, picksDrawer } = useBoardContext();
  const [selectedEntryAmount, setSelectedEntryAmount] = useState<number>(0);
  const [isBoostsAvailableModalOpen, setIsBoostsAvailableModalOpen] = useState(false);

  const balance = walletBalanceQuery.data?.availableBalance ?? 0;
  const groupConfig = useQuickPicksGroupConfig();

  const potentialPayoutAmount = useMemo(() => {
    if (!groupConfig?.multiplier) {
      return 0;
    }

    return Number((groupConfig.multiplier * selectedEntryAmount).toFixed(2));
  }, [groupConfig, selectedEntryAmount]);

  const groupSize = groupConfig.group_size ?? 0;
  const isSplitEntryEligible = groupConfig?.entry_options.find(
    (option) => option.amount === selectedEntryAmount
  )?.split_eligible;

  // Set default entry amount based on settings
  useEffect(() => {
    if (!groupConfig?.entry_fee_options) {
      return;
    }

    if (groupConfig.entry_fee_options.length === 1) {
      setSelectedEntryAmount(groupConfig.entry_fee_options[0]);
      return;
    }

    if (groupConfig.entry_fee_options.includes(selectedEntryAmount)) {
      return;
    }

    setSelectedEntryAmount(groupConfig.entry_fee_options[1]);
  }, [selectedEntryAmount, groupConfig]);

  const handlePickChange = useCallback(
    (id: string, direction: UserDirection) => {
      reportQuickPicksPickEdit({ location: 'entryDetail' });
      picks.edit(id, direction);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [picks.edit]
  );

  const handlePicksRemove = useCallback(
    (id: string) => {
      reportQuickPicksPickRemove({ location: 'entryDetail' });
      picks.remove(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [picks.remove]
  );

  const onSubmit = useEntrySubmit({
    selectedEntryAmount,
    potentialPayoutAmount,
    groupSize,
  });
  const { isSubmitDisabled, submitLabel, error, onClick, boostValidationError } =
    useCreateEntryButton({
      selectedEntryAmount,
      minPicks: MIN_PICK_COUNT,
      onSubmit,
    });

  const handleClose = useCallback(() => {
    if (!picksDrawer.isOpen) {
      return;
    }

    picksDrawer.close();
    reportQuickPicksPicksClose({ numberOfPicks: picks.value.length });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picks.value, picksDrawer.close, picksDrawer.isOpen]);

  const handleEntryAmountChange = useCallback(
    (amount: number) => {
      setSelectedEntryAmount(amount);
      reportQuickPicksPicksChangeEntryFee({
        entryFee: amount,
        numberOfPicks: picks.value.length,
      });
    },
    [picks.value.length]
  );

  const errorMessage = useMemo(() => {
    if (error === 'NOT_ENOUGHT_PICKS') {
      return t('quickPick.board.picksDrawer.errors.minPicks', { count: MIN_PICK_COUNT });
    }

    if (error === 'MUST_PICK_AT_LEAST_TWO_TEAMS') {
      return t('quickPick.board.picksDrawer.errors.atLeastTwoTeam');
    }

    if (error === 'INVALID_BOOST_VALIDATION') {
      return boostValidationError;
    }
  }, [error, t, boostValidationError]);

  return {
    picksDrawerProps: {
      balance,
      entryAmounts: groupConfig.entry_fee_options,
      errorMessage,
      isBalanceHidden: !isUserSignedIn,
      isBalanceError: walletBalanceQuery.isError,
      isBalanceLoading: walletBalanceQuery.isLoading,
      isLoading: isSlipMutationLoading,
      isOpen: picksDrawer.isOpen,
      isSubmitDisabled,
      onClose: handleClose,
      onEntryAmountChange: handleEntryAmountChange,
      onPickChange: handlePickChange,
      onPickRemove: handlePicksRemove,
      onSubmit: onClick,
      picks: picks.value,
      potentialPayoutAmount,
      selectedEntryAmount,
      submitLabel,
      multiplier: groupConfig.multiplier,
      isSplitEntryEligible,
      isBoostsAvailableModalOpen,
      setIsBoostsAvailableModalOpen,
    } satisfies ComponentProps<typeof PicksDrawer>,
    isSettingsDataMissing: !settingsQuery.data,
  };
}

export default usePicksDrawer;
