import { createStyles } from '@mantine/core';

import type { PlayerGamesLogTable } from '../../../game/domains/common/dfs/types/gamesLogTable';
import type { PlayerGroupedStats } from '../../../game/domains/common/dfs/types/player';
import GamecenterPlayerCardGameLog from '../../../gamecenter/domains/player/components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';
import GamecenterPlayerCardStats from '../../../gamecenter/domains/player/components/GamecenterPlayerDetail/GamecenterPlayerDetailStats';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing._8,
  },
}));

function PlayerCardStatsTab({
  groupedStats,
  tableData,
  tableFetchNextPage,
  tableHasNextPage,
  isTableFetchingNextPage,
}: {
  groupedStats?: PlayerGroupedStats;
  tableData?: PlayerGamesLogTable;
  tableFetchNextPage?: () => void;
  tableHasNextPage?: boolean;
  isTableFetchingNextPage?: boolean;
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <GamecenterPlayerCardStats groupedStats={groupedStats} />
      <GamecenterPlayerCardGameLog
        tableData={tableData}
        tableFetchNextPage={tableFetchNextPage}
        tableHasNextPage={tableHasNextPage}
        isTableFetchingNextPage={isTableFetchingNextPage}
      />
    </div>
  );
}

export default PlayerCardStatsTab;
