import { createContext, useContext } from 'react';

import type { TutorialModalContextValue } from './types';

export const TutorialModalContext = createContext<TutorialModalContextValue>({
  isOpen: false,
  onClose: () => {},
  isLoading: true,
  isError: false,
  setIsOpen: () => {},
  tutorialSteps: [],
});

export const useTutorialContext = () => {
  const context = useContext(TutorialModalContext);

  if (context === undefined) {
    throw new Error('useTutorialContext must be used within a TutorialProvider');
  }

  return context;
};
