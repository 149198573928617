import useTranslation from 'next-translate/useTranslation';
import { memo, useCallback, useState } from 'react';
import ContestCountdown from '~/components/utils/ContestCountdown';
import { Button, UnstyledButton } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';
import type { PublicBoostTerms } from '../../../services/interfaces/propsService/api/boosts';
import { useBoardContext } from '../../context/BoardContext/BoardContext';
import { useStyles } from './BoostBanner.styles';
import { BoostIcon } from './BoostIcon';
import BoostChangeModal from './modals/BoostChangeModal';
import BoostTermsModal from './modals/BoostTermsModal';

function BoostBanner({
  id,
  title,
  description,
  expiresAt,
  callout,
  showApplyCTA = true,
  showSelectedBanner = false,
  terms,
  onApply,
  icon,
}: {
  id: string;
  title: string;
  description: string;
  expiresAt: Date;
  callout?: string;
  showApplyCTA?: boolean;
  showSelectedBanner?: boolean;
  terms?: PublicBoostTerms;
  onApply?: () => void;
  icon: string;
}) {
  const { t } = useTranslation('contest');
  const { classes, theme, cx } = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const { selectedBoostId, setSelectedBoostId } = useBoardContext();

  const handleViewTerms = () => {
    setIsDrawerOpen(true);
  };

  const handleApply = () => {
    setSelectedBoostId(id);
    setIsChangeModalOpen(false);
  };

  const handleBoostCTAClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (selectedBoostId) {
        if (selectedBoostId === id) {
          // if the boost is already selected, remove it
          setSelectedBoostId(null);
        } else {
          // if the boost is already selected, open the change modal
          setIsChangeModalOpen(true);
          return;
        }
      } else {
        setSelectedBoostId(id);
      }
      onApply?.();
    },
    [selectedBoostId, id, setSelectedBoostId, onApply]
  );

  const isSelected = selectedBoostId === id;

  return (
    <>
      <UnstyledButton
        className={cx(classes.banner, {
          [classes.selected]: isSelected && showSelectedBanner,
        })}
        onClick={handleViewTerms}
      >
        <div className={classes.wrapper}>
          {icon && <BoostIcon iconUrl={icon} />}
          <div className={classes.content}>
            <Text color={theme.white} variant="subhead-small">
              {title}
            </Text>
            <div className={classes.body}>
              <Text color={theme.colors.gray2[2]} variant="body-small">
                {description}
              </Text>
              <div
                className={cx(classes.bottomText, {
                  [classes.bottomTextWithExpires]: !!expiresAt,
                })}
              >
                {expiresAt && (
                  <div className={classes.expires}>
                    <Text color={theme.white} variant="subhead-x-small">
                      {t('quickPick.boost.expires')}
                    </Text>
                    <ContestCountdown
                      contestStart={expiresAt}
                      color={theme.white}
                      fontSize={10}
                      fontWeight={600}
                    />
                  </div>
                )}
                {!expiresAt && callout && (
                  <Text color={theme.white} variant="subhead-x-small">
                    {callout}
                  </Text>
                )}
              </div>
            </div>
          </div>
          {showApplyCTA && (
            <Button
              onClick={handleBoostCTAClick}
              className={cx(classes.applyCTA, {
                [classes.removeCTA]: isSelected,
              })}
              size="small"
            >
              <Text variant="subhead-small">
                {isSelected ? t('quickPick.boost.remove') : t('quickPick.boost.applyPromo')}
              </Text>
            </Button>
          )}
        </div>
      </UnstyledButton>

      <BoostTermsModal
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onCTAClick={handleBoostCTAClick}
        id={id}
        title={title}
        description={description}
        expiresAt={expiresAt}
        callout={callout}
        terms={terms}
        icon={icon}
      />
      <BoostChangeModal
        isOpen={isChangeModalOpen}
        onClose={() => setIsChangeModalOpen(false)}
        onApply={handleApply}
      />
    </>
  );
}

export default memo(BoostBanner);
