import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';
import ContestCountdown from '~/components/utils/ContestCountdown';
import { Text } from '~/domains/design-system/Text';
import { useBoardContext } from '../../context/BoardContext/BoardContext';
import { useStyles } from './BoostBanner.styles';
import { BoostIcon } from './BoostIcon';

function BoostBanner({
  id,
  title,
  description,
  expiresAt,
  callout,
  showSelectedBanner = false,
  icon,
}: {
  id: string;
  title: string;
  description: string;
  expiresAt: Date;
  callout?: string;
  showSelectedBanner?: boolean;
  icon?: string;
}) {
  const { t } = useTranslation('contest');
  const { classes, theme, cx } = useStyles();
  const { selectedBoostId } = useBoardContext();

  const isSelected = selectedBoostId === id;

  return (
    <div
      className={cx(classes.banner, {
        [classes.selected]: isSelected && showSelectedBanner,
      })}
    >
      <div className={classes.wrapper}>
        {icon && <BoostIcon iconUrl={icon} />}
        <div className={classes.content}>
          <Text color={theme.white} variant="subhead-small">
            {title}
          </Text>
          <div className={classes.body}>
            <Text color={theme.colors.gray2[2]} variant="body-small">
              {description}
            </Text>
            <div
              className={cx(classes.bottomText, {
                [classes.bottomTextWithExpires]: !!expiresAt,
              })}
            >
              {expiresAt && (
                <div className={classes.expires}>
                  <Text color={theme.white} variant="subhead-x-small">
                    {t('quickPick.boost.expires')}
                  </Text>
                  <ContestCountdown
                    contestStart={expiresAt}
                    color={theme.white}
                    fontSize={10}
                    fontWeight={600}
                  />
                </div>
              )}
              {!expiresAt && callout && (
                <Text color={theme.white} variant="subhead-x-small">
                  {callout}
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(BoostBanner);
