import { useCallback, useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import {
  getUseLocalStorageSettingsKey,
  useLocalStorageSettings,
} from '~/domains/common/hooks/useLocalStorageSettings';

const KEY_PREFIX = 'picksheet';

function useStoredPicksheet<T>({ contestId, slateId }: { contestId: string; slateId?: string }) {
  const { user } = useContext(UserContext);

  const anonKey = [KEY_PREFIX, contestId, slateId].filter(Boolean).join('::');
  const authKey = [KEY_PREFIX, user?.id, contestId, slateId].filter(Boolean).join('::');
  const [anonValue, setAnonValue] = useLocalStorageSettings<T>(anonKey, null);
  const [authValue, setAuthValue] = useLocalStorageSettings<T>(authKey, null);

  const setValue = user ? setAuthValue : setAnonValue;

  const getValue = useCallback(() => {
    if (!user) {
      return anonValue;
    }

    return authValue || anonValue;
  }, [anonValue, authValue, user]);

  const clearAnonValue = useCallback(() => {
    localStorage.removeItem(getUseLocalStorageSettingsKey(anonKey));
  }, [anonKey]);

  const clearValue = useCallback(() => {
    try {
      if (!user) {
        clearAnonValue();
      } else {
        localStorage.removeItem(getUseLocalStorageSettingsKey(authKey));
      }
    } catch (e) {
      console.error(e);
    }
  }, [authKey, clearAnonValue, user]);

  return {
    setStoredPicks: setValue,
    getStoredPicks: getValue,
    clearStoredPicks: clearValue,
    clearStoredAnonPicks: clearAnonValue,
  };
}

export { useStoredPicksheet };
