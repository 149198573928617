import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateSlipPayload } from '../../services/interfaces/propsService/api';
import { postSlip } from '../../services/props.service.api';
import { reportQuickPicksEntrySubmit } from '../analyticsEvents';
import QuickPickQueryKeys from '../constants/query';

const useQuickPicksCreateSlipMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CreateSlipPayload) => postSlip(payload),
    mutationKey: QuickPickQueryKeys.CREATE_SLIP(),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [QuickPickQueryKeys.QUICK_PICKS_SLIPS(), QuickPickQueryKeys.QUICK_PICKS_BOOSTS()],
      });

      reportQuickPicksEntrySubmit({
        entryFee: variables.amount,
        numberOfPicks: variables.props.length,
      });
    },
  });
};

export default useQuickPicksCreateSlipMutation;
