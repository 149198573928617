import { createStyles } from '@mantine/core';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef } from 'react';
import Modal from '~/domains/common/components/Modal/Modal/Modal';
import { SplashLoader } from '~/domains/common/components/SplashLoader/SplashLoader';
import UnknownErrorScreen from '~/domains/common/components/UnknownErrorScreen/UnknownErrorScreen';
import { Button } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';
import { useBoardContext } from '../../../context/BoardContext/BoardContext';
import BoostBanner from '../BoostBannerButton';

const useStyles = createStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.other.spacing._16,
    overflow: 'scroll',
    maxHeight: '50vh',
    color: theme.colors.gray2[2],
    fontSize: '10px',
    marginBottom: 16,
  },
  container: {
    padding: '0 16px',
  },
  modal: {
    padding: '0 !important',
    borderTopLeftRadius: theme.other.spacing._32,
    borderTopRightRadius: theme.other.spacing._32,
  },
  header: {
    borderBottom: `1px solid ${theme.colors.gray2[0]}`,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 16px 0 16px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    gap: theme.other.spacing._16,
  },
  closeButton: {
    padding: '0',
  },
  row: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    gap: 8,
  },
}));

type BoostsAvailableModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function BoostsAvailableModal({ isOpen, onClose }: BoostsAvailableModalProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('contest');
  const { boosts, boostsData } = useBoardContext();
  const listRef = useRef<HTMLDivElement | null>(null);

  const virtualizer = useWindowVirtualizer({
    count: boostsData.hasNextPage ? boosts.length + 1 : boosts.length,
    estimateSize: () => 50,
    overscan: 20,
    scrollMargin: listRef.current?.offsetTop ?? 0,
    gap: 8,
  });

  const virtualRows = virtualizer.getVirtualItems();

  useEffect(() => {
    if (!isOpen) return;

    const [lastItem] = virtualRows.slice(-1);

    if (!lastItem) return;

    if (
      lastItem.index >= boosts.length - 1 &&
      boostsData.hasNextPage &&
      !boostsData.isFetchingNextPage
    ) {
      boostsData.fetchNextPage();
    }
  }, [boosts.length, boostsData, virtualRows]);

  return (
    <Modal
      isOpened={isOpen}
      classNames={{
        drawer: {
          body: classes.modal,
        },
        modal: {
          modal: classes.modal,
        },
      }}
      onClose={onClose}
      withCloseButton={false}
    >
      <div className={classes.body}>
        <div className={classes.header}>
          <div className={classes.headerContent}>
            <Text variant="headline-medium">{t('quickPick.boost.promotions')}</Text>
            <Button className={classes.closeButton} onClick={onClose} variant="text">
              <Text variant="title-large">{t('quickPick.boost.close')}</Text>
            </Button>
          </div>
        </div>
        <div className={classes.content}>
          {boostsData.isLoading && <SplashLoader />}
          {boostsData.isError && <UnknownErrorScreen />}
          {!boostsData.isLoading && !boostsData.isError && (
            <div className={classes.container}>
              <div
                ref={listRef}
                style={{
                  height: `${virtualizer.getTotalSize()}px`,
                  position: 'relative',
                }}
              >
                {virtualRows.map((row, index) => {
                  const boost = boosts?.[index];
                  return (
                    <div
                      key={row.key}
                      className={classes.row}
                      style={{
                        transform: `translateY(${row.start - virtualizer.options.scrollMargin}px)`,
                      }}
                      data-index={row.index}
                      ref={virtualizer.measureElement}
                    >
                      {!!boost && (
                        <div style={{ width: '100%' }} key={row.key}>
                          <BoostBanner
                            id={boost.id}
                            title={boost.title}
                            description={boost.description}
                            expiresAt={boost.expires_at}
                            callout={boost.callout}
                            showApplyCTA
                            terms={boost.terms}
                            onApply={onClose}
                            showSelectedBanner
                            icon={boost.svg_url}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default BoostsAvailableModal;
