import { useBoardContext } from '../../context/BoardContext/BoardContext';
import BoostBanner from './BoostBannerButton';
import BoostsAvailable from './BoostsAvailable';

function BoostsCTA({
  isBoostsAvailableModalOpen,
  setIsBoostsAvailableModalOpen,
}: {
  isBoostsAvailableModalOpen: boolean;
  setIsBoostsAvailableModalOpen: (open: boolean) => void;
}) {
  const { selectedBoost, boosts, totalBoostsCount } = useBoardContext();

  if (!selectedBoost) {
    return (
      <BoostsAvailable
        amount={totalBoostsCount}
        isOpen={isBoostsAvailableModalOpen}
        setIsOpen={setIsBoostsAvailableModalOpen}
      />
    );
  }

  return (
    <BoostBanner
      id={selectedBoost.id}
      title={selectedBoost.title}
      description={selectedBoost.description}
      expiresAt={selectedBoost.expires_at}
      callout={selectedBoost.callout}
      showApplyCTA
      terms={selectedBoost.terms}
      icon={selectedBoost.svg_url}
    />
  );
}

export default BoostsCTA;
