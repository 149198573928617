import { useQueryClient } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import { useLocationState } from '~/hooks/useLocationState';

import type { TGetPropsResponse } from '../../../services/interfaces/propsService/parsed/props';
import {
  reportQuickPicksPickEdit,
  reportQuickPicksPickMake,
  reportQuickPicksPickRemove,
} from '../../analyticsEvents';
import { useBoardContext } from '../../context/BoardContext/BoardContext';
import { useQuickPickLocationAllowed } from '../../hooks/useQuickPickLocationAllowed';
import { useQuickPicksProps } from '../../hooks/useQuickPicksProps';
import { useQuickPicksSettings } from '../../hooks/useQuickPicksSettings';
import type { PropPickProps } from '../../types/propPickProps.type';
import { parsePropToComponentProp } from '../../utils/parsePropToComponentProp';

import type { PlayerCellProps } from './PlayerCell/PlayerCell';

const DEFAULT_ERROR_TIMEOUT = 5_000;

function useBoard({
  isPromo,
  isSearch,
  onFirstPageSuccess,
}: {
  isPromo?: boolean;
  isSearch?: boolean;
  onFirstPageSuccess?: (data: TGetPropsResponse) => void;
} = {}) {
  const { t } = useTranslation('contest');
  const { picks, filters, error } = useBoardContext();

  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['wallets/balance', user?.id],
    });
  }, [queryClient, user]);

  const filtersValue = useMemo(() => {
    const { league, search, ...rest } = filters.value;
    if (isSearch) {
      return { league: league === 'popular' ? undefined : league, search };
    }

    return { league, ...rest };
  }, [filters.value, isSearch]);

  const propsQuery = useQuickPicksProps({
    filters: filtersValue,
    onFirstPageSuccess,
  });
  const settingsQuery = useQuickPicksSettings();
  const selectedPicksMap = useMemo(
    () => picks.value.reduce((acc, pick) => ({ ...acc, [pick.id]: pick }), {}),
    [picks.value]
  );
  const isLocationAllowed = useQuickPickLocationAllowed();
  const locationState = useLocationState();

  const maxPicks = settingsQuery.data?.peer_to_peer_config?.options.slice(-1)[0]?.slip_length ?? 2;

  const handlePick = useCallback(
    (pick: PropPickProps) => {
      const pickLocation = isPromo ? 'homePromo' : 'board';
      const isPicked = selectedPicksMap[pick.id];
      const isPickedAndSameDirection =
        isPicked && selectedPicksMap[pick.id].direction === pick.direction;
      if (isPicked) {
        if (isPickedAndSameDirection) {
          reportQuickPicksPickRemove({ location: pickLocation });
          picks.remove(pick.id);
        } else {
          reportQuickPicksPickEdit({ location: pickLocation });
          picks.edit(pick.id, pick.direction);
        }
      } else {
        if (picks.value.length >= maxPicks) {
          error.showError(
            t('quickPick.board.errors.maxEntriesReached', { count: maxPicks }),
            DEFAULT_ERROR_TIMEOUT
          );
          return;
        }
        if (picks.value.some((p) => p.playerId === pick.playerId)) {
          error.showError(
            t('quickPick.board.errors.samePlayer', { count: maxPicks }),
            DEFAULT_ERROR_TIMEOUT
          );
          return;
        }

        reportQuickPicksPickMake({ location: pickLocation });
        picks.add(pick);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [picks.remove, picks.edit, picks.add, selectedPicksMap, maxPicks]
  );

  const handlePlayerCardClick = useCallback(
    (id: string) => {
      filters.set('playerCardId', id);
    },
    [filters]
  );

  const items = useMemo(() => {
    if (!propsQuery.data) return [];
    const keys = new Set<string>([]);

    return propsQuery.data.pages.flatMap((page) =>
      page.data.reduce((acc, prop) => {
        const key = `${prop.game_id}:${prop.entity_id}:${prop.type}`;
        if (keys.has(key)) {
          return acc;
        }
        keys.add(key);

        acc.push({
          ...parsePropToComponentProp(prop, filters.query.flattenedTypes),
          onPick: handlePick,
          onHeaderClick: handlePlayerCardClick,
          direction: selectedPicksMap[prop.id]?.direction,
        } satisfies PlayerCellProps);

        return acc;
      }, [])
    );
  }, [propsQuery.data, filters.query.flattenedTypes, handlePick, selectedPicksMap]);

  return {
    propsQuery,
    items,
    isLocationAllowed,
    locationState,
  };
}

export default useBoard;
