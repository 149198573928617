import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type { PlayerGamesLogTable } from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

import parseBaseStats from './parseBaseStats';
import parseQB from './parseQB';
import parseRB from './parseRB';
import parseWRorTE from './parseWRorTE';

const parsers: {
  [position: string]: (games: PlayerSeasonGame[]) => PlayerGamesLogTable;
} = {
  QB: parseQB,
  RB: parseRB,
  TE: parseWRorTE,
  WR: parseWRorTE,
};

const parseFootballGames = (
  playerSeasonGames: PlayerSeasonGame[],
  position?: string
): PlayerGamesLogTable => {
  if (playerSeasonGames.length === 0) return [];

  const baseStats = parseBaseStats(playerSeasonGames);
  if (!position) return baseStats;

  const parser = parsers[position] ?? (() => []);

  return [...baseStats, ...parser(playerSeasonGames)];
};

export default parseFootballGames;
