import { showNotification } from '@mantine/notifications';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useCallback, useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import { Routes } from '~/domains/common/constants/routes';
import type { PropsSettings } from '~/domains/contest/domains/services/interfaces/props-settings.interface';
import type { TValidateSlipErrorResponse } from '~/domains/contest/domains/services/interfaces/propsService/parsed/slips';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';
import { isApiError } from '~/domains/services/utils/isApiError';
import { isSplashApiError } from '~/domains/services/utils/isBEApiError';

import { reportQuickPicksPicksAlert } from '../../../analyticsEvents';
import { useBoardContext } from '../../../context/BoardContext/BoardContext';
import useEntryLimitReachedAlert from '../../../context/QuickPickConfirmationModalContext/hooks/useEntryLimitReachedAlert';
import useQuickPicksCreateSlipMutation from '../../../hooks/useQuickPicksCreateSlipMutation';
import { parsePropToComponentProp } from '../../../utils/parsePropToComponentProp';

function useEntrySubmit({
  selectedEntryAmount,
  potentialPayoutAmount,
  groupSize,
  entryLimits,
}: {
  selectedEntryAmount: number;
  potentialPayoutAmount: number;
  groupSize: number;
  entryLimits?: PropsSettings['peer_to_peer_config']['in_play'];
}) {
  const { t } = useTranslation('contest');
  const { picks, picksDrawer, picksAlert, filters, picksSuccess, selectedBoost } =
    useBoardContext();
  const createSlipMutation = useQuickPicksCreateSlipMutation();
  const walletBalanceQuery = useWalletBalance();
  const { isUserSignedIn } = useContext(UserContext);
  const router = useRouter();
  const showEntryLimitReachedAlert = useEntryLimitReachedAlert();

  const handleShowAlert = useCallback(
    (alertResponse: TValidateSlipErrorResponse) => {
      const propsChanged = Object.keys(alertResponse.data.changes).length;

      reportQuickPicksPicksAlert({
        numberOfChanges: alertResponse.data.inactive_prop_ids.length,
        propChanged: !!propsChanged,
        propRemoved: alertResponse.data.inactive_prop_ids.length > propsChanged,
      });

      picksDrawer.close();
      picksAlert.setPayload(
        alertResponse.data.inactive_prop_ids.map((pickId) => {
          const pick = picks.value.find((p) => p.id === pickId);
          const changedPick = alertResponse.data.changes[pickId];

          return {
            oldProp: pick,
            newProp: changedPick
              ? parsePropToComponentProp(changedPick, filters.query.flattenedTypes)
              : undefined,
          };
        })
      );
      picksAlert.open();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [picksAlert.open, picksAlert.setPayload, picksDrawer.close, picks.value]
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (!isUserSignedIn) {
        router.push(Routes.signIn({ redirectTo: router.asPath }));
        return;
      }

      const currentComboEntryLimit = entryLimits?.[picks.value.length]?.[selectedEntryAmount];

      if (
        currentComboEntryLimit &&
        currentComboEntryLimit.max_entries === currentComboEntryLimit.slip_count
      ) {
        picksDrawer.close();
        showEntryLimitReachedAlert();
        return;
      }

      const response = await createSlipMutation.mutateAsync({
        props: picks.value.map((pick) => ({
          id: pick.id,
          direction: pick.direction,
        })),
        amount: selectedEntryAmount,
        payout_multiplier_type: 'peer_to_peer',
        boost_id: selectedBoost?.id,
      });

      if (response.status !== 'success') {
        handleShowAlert(response);
        return;
      }

      picks.reset();
      picksDrawer.close();
      walletBalanceQuery.refetch();
      picksSuccess.setPayload({
        picks: picks.value,
        entryAmount: selectedEntryAmount,
        potentialPayoutAmount,
        groupSize,
      });
      picksSuccess.open();
    } catch (e) {
      let message = e.message;
      if (isSplashApiError(e)) {
        message = e.response.data.message;
      } else if (isApiError(e)) {
        message = e.response.statusText;
      }
      showNotification({
        title: t('quickPick.board.picksDrawer.errors.apiError.title'),
        message,
        color: 'orange.3',
        autoClose: 5000,
      });
    }
  }, [
    createSlipMutation,
    entryLimits,
    groupSize,
    handleShowAlert,
    isUserSignedIn,
    picks,
    picksDrawer,
    picksSuccess,
    potentialPayoutAmount,
    router,
    selectedEntryAmount,
    showEntryLimitReachedAlert,
    t,
    walletBalanceQuery,
  ]);

  return handleSubmit;
}

export default useEntrySubmit;
