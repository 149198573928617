import type {
  APIPlayerSeasonGame,
  PlayerSeasonGame,
} from '~/domains/games/interfaces/player-season.interface';

const parsePlayerSeasonData = (data: APIPlayerSeasonGame[]): PlayerSeasonGame[] =>
  data.map((game) => ({
    ...game,
    gameStartDate: new Date(game.gameStartDate),
  }));

export default parsePlayerSeasonData;
