import { useTheme } from '@emotion/react';

const useBarChartLayout = ({ referenceLineValue, invertColors, variant }) => {
  const theme = useTheme();

  const getLabelColor = (value) => {
    const greenBar = { barColor: theme.colors.green[2], borderColor: theme.colors.green[3] };
    const grayBar = { barColor: theme.colors.gray2[2], borderColor: theme.colors.gray2[4] };

    if (referenceLineValue && value > referenceLineValue) {
      return invertColors ? greenBar : grayBar;
    }
    return invertColors ? grayBar : greenBar;
  };

  const layoutConfig =
    variant === 'simple'
      ? {
          barChartMargin: undefined,
          showHorizontalGrid: false,
          showXAxis: false,
          showYAxis: false,
          showLabelList: false,
          yAxisPadding: { top: 0, bottom: 0 },
          isAnimationActive: false,
          barSize: 4,
          barTopHeight: 1,
        }
      : {
          barChartMargin: {
            top: 5,
            left: -10,
            right: 20,
            bottom: 5,
          },
          showHorizontalGrid: true,
          showXAxis: true,
          showYAxis: true,
          showLabelList: true,
          yAxisPadding: { top: 16, bottom: 1 },
          isAnimationActive: true,
          barSize: 24,
          barTopHeight: 2,
        };

  return {
    getLabelColor,
    layoutConfig,
  };
};

export default useBarChartLayout;
