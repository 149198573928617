import { useCallback, useEffect, useMemo, useState } from 'react';

import type { UserDirection } from '../../../services/interfaces/propsService/api';
import type { PropPickProps } from '../../types/propPickProps.type';
import useStoredPicks from '../../components/Board/PicksDrawer/useStoredPicks';

function usePicks() {
  const { getStoredPicks, setStoredPicks, clearStoredPicks, clearStoredAnonPicks } =
    useStoredPicks();
  const [selectedPicks, setSelectedPicks] = useState<PropPickProps[]>(getStoredPicks() ?? []);

  const addPick = useCallback((pick: PropPickProps) => {
    setSelectedPicks((prev) => [...prev, pick]);
  }, []);

  const removePick = useCallback((pickId: string) => {
    setSelectedPicks((prev) => prev.filter((p) => p.id !== pickId));
  }, []);

  const editPick = useCallback((pickId: string, direction: UserDirection) => {
    setSelectedPicks((prev) => prev.map((p) => (p.id === pickId ? { ...p, direction } : p)));
  }, []);

  const resetPicks = useCallback(() => {
    setSelectedPicks([]);
    clearStoredAnonPicks();
  }, [clearStoredAnonPicks]);

  useEffect(() => {
    if (selectedPicks.length === 0) {
      clearStoredPicks();
    } else {
      setStoredPicks(selectedPicks);
    }
  }, [clearStoredPicks, selectedPicks, setStoredPicks]);

  return useMemo(
    () => ({
      value: selectedPicks,
      add: addPick,
      remove: removePick,
      edit: editPick,
      set: setSelectedPicks,
      reset: resetPicks,
    }),
    [addPick, editPick, removePick, resetPicks, selectedPicks]
  );
}

export default usePicks;
