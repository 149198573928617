import { createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import Confetti from 'react-confetti';

import { CurrencyConvertor } from '~/components/utils/formatters';
import Modal from '~/domains/common/components/Modal/Modal/Modal';
import { Button } from '~/domains/design-system/Button';
import { Text, textStyles } from '~/domains/design-system/Text';

import type { PropPickProps } from '../../../../types/propPickProps.type';

import type { PublicBoost } from '~/domains/contest/domains/services/interfaces/propsService/api/boosts';
import { BoostIcon } from '../../../Boosts/BoostIcon';
import EntrySubmittedPick from './EntrySubmittedPick';

const useStyles = createStyles((theme) => ({
  confettiContainer: {
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    pointerEvents: 'none',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing._16,
    overflow: 'hidden',

    [theme.fn.smallerThan('sm')]: {
      maxHeight: `calc(100svh - ${theme.other.spacing._16 * 2}px)`,
    },
  },
  title: {
    ...textStyles['headline-medium'],
    color: theme.colors.gray2[6],
  },
  text: {
    ...textStyles['body-medium'],
    color: theme.colors.gray2[3],
  },
  picksContainer: {
    border: `1px solid ${theme.colors.gray2[1]}`,
    borderRadius: theme.other.spacing._8,
    overflow: 'hidden',
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  picksHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.other.spacing._8}px ${theme.other.spacing._16}px`,
    background: theme.colors.gray2[0],
    ...textStyles['body-large'],
    color: theme.colors.gray2[6],
    flexShrink: 0,

    '& strong': {
      ...textStyles['subhead-medium'],

      '&.green': {
        color: theme.colors.green[3],
      },
    },
  },
  picksList: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  pick: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colors.gray2[1]}`,
    },
  },
  actions: {
    flexShrink: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.other.spacing._8,
  },
  drawer: {
    borderTopLeftRadius: theme.other.spacing._16,
    borderTopRightRadius: theme.other.spacing._16,
  },
  drawerBody: {
    borderTopLeftRadius: theme.other.spacing._16,
    borderTopRightRadius: theme.other.spacing._16,
    paddingTop: theme.other.spacing._16,
    maxHeight: '100svh',
    overflow: 'hidden',
  },
  boostAppliedContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    padding: '8px 16px',
    justifyContent: 'flex-start',
    background:
      'linear-gradient(90deg, rgba(75, 235, 226, 0.20) 0%, rgba(218, 71, 247, 0.20) 100%), #1A1C1E',

    borderRadius: '4px',
  },
  boostedPayout: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
  },
  strikethrough: {
    textDecoration: 'line-through',
  },
}));

function EntrySubmitted({
  isOpened,
  picks,
  groupSize,
  entryAmount,
  potentialPayoutAmount,
  selectedBoost,
  onClose,
  onPicksReuse,
}: {
  isOpened: boolean;
  picks: PropPickProps[];
  groupSize: number;
  entryAmount: number;
  potentialPayoutAmount: number;
  selectedBoost?: PublicBoost;
  onClose: () => void;
  onPicksReuse: () => void;
}) {
  const { t } = useTranslation('contest');
  const { classes, theme } = useStyles();
  const boostedPayout =
    selectedBoost?.bet_options?.[picks.length]?.[entryAmount]?.total_payout ?? 0;

  return (
    <>
      {isOpened && (
        <div className={classes.confettiContainer}>
          <Confetti recycle={false} />
        </div>
      )}
      <Modal
        isOpened={isOpened}
        onClose={onClose}
        classNames={{
          drawer: {
            drawer: classes.drawer,
            body: classes.drawerBody,
          },
        }}
      >
        <div className={classes.content}>
          <div className={classes.title}>{t('quickPick.board.entrySubmitted.title')}</div>

          <div className={classes.text}>
            {t('quickPick.board.entrySubmitted.subtitle', { groupSize })}
          </div>

          {selectedBoost && (
            <div className={classes.boostAppliedContainer}>
              {selectedBoost.svg_url && <BoostIcon iconUrl={selectedBoost.svg_url} />}
              <Text color={theme.white}>
                {t('quickPick.board.entrySubmitted.boostApplied', {
                  selectedBoost: selectedBoost.title,
                })}
              </Text>
            </div>
          )}

          <div className={classes.picksContainer}>
            <div className={classes.picksHeading}>
              <div>
                {t('quickPick.board.entrySubmitted.picksCount', { count: picks.length })}:{' '}
                <strong>{CurrencyConvertor(entryAmount)}</strong>
              </div>
              <div>
                {t('quickPick.board.entrySubmitted.winUpTo')}{' '}
                {selectedBoost?.category === 'win' ? (
                  <div className={classes.boostedPayout}>
                    <Text
                      className={classes.strikethrough}
                      variant="subhead-medium"
                      color={theme.colors.gray2[2]}
                    >
                      {CurrencyConvertor(potentialPayoutAmount)}
                    </Text>
                    <Text variant="subhead-medium" color={theme.colors.green[3]}>
                      {CurrencyConvertor(boostedPayout)}
                    </Text>
                  </div>
                ) : (
                  <strong className="green">{CurrencyConvertor(potentialPayoutAmount)}</strong>
                )}
              </div>
            </div>
            <div className={classes.picksList}>
              {picks.map((pick) => (
                <div className={classes.pick} key={pick.id}>
                  <EntrySubmittedPick {...pick} />
                </div>
              ))}
            </div>
          </div>

          <div className={classes.actions}>
            <Button variant="tertiary-fill" onClick={onPicksReuse}>
              {t('quickPick.board.entrySubmitted.picksReuse')}
            </Button>
            <Button variant="secondary-fill" onClick={onClose}>
              {t('quickPick.board.entrySubmitted.close')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EntrySubmitted;
