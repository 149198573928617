import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { GetPropsFiltersPayload } from '../../../services/interfaces/propsService/api';
import { useQuickPicksFilters } from '../../hooks/useQuickPicksFilters';

const filterKeys: (keyof GetPropsFiltersPayload)[] = [
  'league',
  'type',
  'game',
  'search',
  'playerCardId',
];

export type SetFilterFn = <K extends keyof GetPropsFiltersPayload>(
  key: K,
  newValue:
    | GetPropsFiltersPayload[K]
    | null
    | ((prevValue: GetPropsFiltersPayload[K]) => GetPropsFiltersPayload[K] | null)
) => void;

function useFilters() {
  const router = useRouter();
  const [value, setValue] = useState<GetPropsFiltersPayload>({
    league: (router.query.league as string) ?? null,
    type: (router.query.type as string) ?? null,
    game: (router.query.game as string[]) ?? [],
    search: (router.query.search as string) ?? '',
    playerCardId: (router.query.playerCardId as string) ?? null,
  });
  const isSearchPage = router.asPath.includes('/search');

  const filtersQuery = useQuickPicksFilters();

  const setFilter: SetFilterFn = useCallback((key, newValueOrCb) => {
    setValue((prev) => {
      const updatedValue = { ...prev };
      let newValue: GetPropsFiltersPayload[typeof key] | null;
      if (typeof newValueOrCb === 'function') {
        newValue = newValueOrCb(prev[key]);
      } else {
        newValue = newValueOrCb;
      }

      if (newValue === null) {
        delete updatedValue[key];
      } else {
        updatedValue[key] =
          newValue === null || newValue === '' || newValue?.length === 0 ? null : newValue;
      }
      if (key === 'league') {
        updatedValue.type = null;
        updatedValue.game = null;
      }

      return updatedValue;
    });
  }, []);

  const resetFilters = useCallback(() => {
    setValue({
      league: null,
      type: null,
      game: null,
      search: '',
      playerCardId: null,
    });
  }, []);

  // Set query according to filters
  useEffect(() => {
    const newQuery = { ...router.query };

    filterKeys.forEach((key) => {
      if (!value[key] || value[key] === null) {
        delete newQuery[key];
      } else {
        newQuery[key] = value[key];
      }
    });
    router.push({ query: newQuery }, undefined, { shallow: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run this once value changes, not router
  }, [value]);

  // Set default league if league is missing
  useEffect(() => {
    if (!filtersQuery.data) return;
    if (!value.league) {
      if (isSearchPage) {
        return;
      }
      setFilter('league', filtersQuery.data.filters.leagues[0].id);
    }
    if (!value.type) {
      const activeLeague = value.league;
      const activeType = filtersQuery.data.filters.leagues
        .find((league) => league.id === activeLeague)
        ?.types.find((type) => type.active);
      if (activeType) {
        setFilter('type', activeType.id);
      }
    }
  }, [filtersQuery.data, router, isSearchPage, setFilter, value.league, value.type]);

  return useMemo(
    () => ({
      value,
      set: setFilter,
      reset: resetFilters,
      query: filtersQuery,
    }),
    [filtersQuery, setFilter, value, resetFilters]
  );
}

export default useFilters;
