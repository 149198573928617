import { createStyles } from '@mantine/core';

import { textStyles } from '~/domains/design-system/Text';

export const useStyles = createStyles((theme) => ({
  playerCell: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: theme.radius.md,
    backgroundColor: 'white',
    border: `2px solid transparent`,
    width: '100%',
  },
  selected: {
    border: `2px solid ${theme.colors.blue[9]}`,
  },
  infoIcon: {
    position: 'absolute',
    top: theme.other.spacing._4,
    left: theme.other.spacing._4,
    zIndex: theme.other.zIndex.tooltip.default,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  playerCellHeader: {
    position: 'relative',
    width: '100%',
    height: 108,
    borderRadius: theme.radius.md,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: `linear-gradient(180deg, #FFFFFF 0%, #F1F3F5 100%)`,
    overflow: 'hidden',
  },
  playerCellProps: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    gap: 0,
    top: theme.other.spacing._8,
    right: theme.other.spacing._8,
  },
  playerCellHeaderBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 108,
    opacity: 0.08,
    backgroundSize: '200px 160px',
    backgroundPosition: '-60px -25px',
    backgroundRepeat: 'no-repeat',
  },
  playerCellHeaderImage: {
    position: 'absolute',
    left: theme.other.spacing._12,
    bottom: 0,
  },
  playerCellProps_value: {
    fontSize: '20px',
    lineHeight: '20px',
    fontFamily: theme.other.fontFamily.hubotSans,
    fontStretch: '75%',
    fontWeight: 900,
    color: theme.colors.gray2[6],
  },
  playerCellProps_type: {
    fontSize: '12px',
    fontFamily: theme.other.fontFamily.hubotSans,
    textAlign: 'right',
    fontStretch: '75%',
    color: theme.colors.gray2[6],
    maxWidth: '80px',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
  },
  playerCellBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.other.spacing._8,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    cursor: 'default',
  },
  playerCellBody_name_position: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  playerCellBody_name: {
    ...textStyles['headline-medium'],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  playerCellBody_position: {
    color: theme.colors.gray2[3],
    ...textStyles['subhead-x-small'],
  },
  playerCellBody_details: {
    color: theme.colors.gray2[3],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    ...textStyles['body-small'],

    '& strong': {
      ...textStyles['subhead-x-small'],
    },
  },
  playerCellFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: theme.other.spacing._8,
    paddingTop: 0,
    gap: theme.other.spacing._8,
  },
  playerCellFooter_button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 4px 8px 4px',
    gap: '4px',
    width: '100%',
  },
  teamAlias: {
    position: 'absolute',
    top: '-16px',
    fontSize: '96px',
    fontStyle: 'italic',
    WebkitTextStroke: '2px',
    WebkitTextFillColor: theme.colors.gray[1],
    fontFamily: theme.other.fontFamily.hubotSansBlack,
    opacity: 0.1,
    textAlign: 'center',
    width: '100%',
    right: '48px',
  },
}));
