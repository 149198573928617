import { createStyles } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';

import { textStyles } from '~/domains/design-system/Text';

import PlayerImageCircle from '../../PlayerImageCircle';
import GameTeamsLabel from '../../GameTeamsLabel';
import type { PropPickProps } from '../../../../types/propPickProps.type';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing._8,
    padding: theme.other.spacing._16,
  },
  image: {},
  pickInfo: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  titleAndPosition: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    ...textStyles['headline-medium'],
    color: theme.colors.gray2[6],
  },
  position: {
    ...textStyles['subhead-small'],
    color: theme.colors.gray2[3],
  },
  subtitle: {
    ...textStyles['body-medium'],
    color: theme.colors.gray2[3],

    '& strong': {
      ...textStyles['subhead-small'],
    },
  },
  line: {
    overflow: 'hidden',
    height: '100%',
  },
  lineValue: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: theme.other.fontFamily.hubotSans,
    fontStretch: '75%',
    fontWeight: 900,
    color: theme.colors.gray2[6],
    textAlign: 'right',
  },
  lineLabel: {
    fontSize: '12px',
    fontFamily: theme.other.fontFamily.hubotSans,
    textAlign: 'right',
    flexShrink: 1,

    fontStretch: '75%',
    color: theme.colors.gray2[6],

    maxWidth: '80px',
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.other.spacing._8,
  },
  direction: {
    height: theme.other.spacing._32,
    width: theme.other.spacing._32,
    borderRadius: theme.other.spacing._4,
    background: theme.colors.blue[3],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.other.spacing._8,
  },
}));

function EntrySubmittedPick({
  awayTeam,
  direction,
  homeTeam,
  line,
  playerImage,
  playerName,
  playerTeamId,
  position,
  startDate,
}: PropPickProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <PlayerImageCircle src={playerImage} alt="Name" />
      <div className={classes.pickInfo}>
        <div className={classes.titleAndPosition}>
          <div className={classes.title}>{playerName}</div>
          {position && (
            <div className={classes.position}>
              &nbsp;•&nbsp;
              {position}
            </div>
          )}
        </div>
        <div className={classes.subtitle}>
          <GameTeamsLabel homeTeam={homeTeam} awayTeam={awayTeam} playerTeamId={playerTeamId} />
        </div>
        <div className={classes.subtitle}>{startDate}</div>
      </div>
      <div className={classes.line}>
        <div className={classes.lineValue}>{line.value}</div>
        <div className={classes.lineLabel}>{line.label}</div>
      </div>
      <div className={classes.direction}>
        {direction === 'over' ? <IconArrowUp size={16} /> : <IconArrowDown size={16} />}
      </div>
    </div>
  );
}

export default EntrySubmittedPick;
