import { useCallback, useMemo, type ComponentProps } from 'react';

import { useBoardContext } from '../../../context/BoardContext/BoardContext';

import type EntryPicksChangedAlert from './EntryPicksChangedAlert/EntryPicksChangedAlert';

function useEntryPicksChangedAlerts() {
  const { picksAlert, picks, picksDrawer } = useBoardContext();

  const handleSubmit = useCallback(() => {
    const changedPicks = picksAlert.payload ?? [];
    picks.set((prev) =>
      prev
        .map((pick) => {
          const pickUpdate = changedPicks.find((p) => p.oldProp.id === pick.id);
          if (!pickUpdate) return pick;

          if (!pickUpdate.newProp) {
            return null;
          }

          return {
            ...pickUpdate.newProp,
            direction: pick.direction,
          };
        })
        .filter(Boolean)
    );
    picksAlert.close();
    picksDrawer.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picksAlert.open, picks.value, picksDrawer.close, picksAlert.payload]);

  return useMemo(
    () =>
      ({
        isOpened: picksAlert.isOpen,
        picks: picksAlert.payload ?? [],
        onSubmit: handleSubmit,
      }) satisfies ComponentProps<typeof EntryPicksChangedAlert>,
    [picksAlert.isOpen, picksAlert.payload, handleSubmit]
  );
}

export default useEntryPicksChangedAlerts;
