import { createStyles, keyframes } from '@mantine/core';

import { textStyles } from '~/domains/design-system/Text';

import selectionImage from './selection.svg';

const onMountAnimation = keyframes({
  from: {
    transform: 'translateY(200px)',
  },
  to: {
    transform: 'translateY(0)',
  },
});

const useStyles = createStyles((theme, _, getRef) => ({
  container: {
    animation: `${onMountAnimation} 0.3s ease-out`,
    position: 'relative',
  },
  errorMessage: {
    position: 'absolute',
    borderRadius: `${theme.other.spacing._16}px ${theme.other.spacing._16}px 0 0`,
    background: theme.colors.orange[3],
    padding: theme.other.spacing._16,
    paddingTop: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    zIndex: 1,
    bottom: theme.other.spacing._16,

    '&.enter': {
      bottom: theme.other.spacing._16,
      transition: 'bottom 0.3s ease-out',
    },
    '&.enter-active': {
      bottom: `calc(100% - ${theme.other.spacing._16}px)`,
    },
    '&.enter-done': {
      bottom: `calc(100% - ${theme.other.spacing._16}px)`,
    },
    '&.exit': {
      bottom: `calc(100% - ${theme.other.spacing._16}px)`,
      transition: 'bottom 0.3s ease-out',
    },
    '&.exit-active': {
      bottom: theme.other.spacing._16,
    },
  },
  errorMessageContent: {
    padding: `${theme.other.spacing._4}px ${theme.other.spacing._16}px`,
    textAlign: 'center',
    ...textStyles['title-small'],
    color: theme.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.fn.largerThan('md')]: {
      padding: `${theme.other.spacing._8}px ${theme.other.spacing._16}px`,
      ...textStyles['title-medium'],
    },
  },
  mainPanel: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${theme.other.spacing._16}px`,
    height: 64,
    borderRadius: theme.other.spacing._16,
    background:
      'linear-gradient(104deg, rgba(37, 38, 43, 0.20) 0%, rgba(75, 235, 226, 0.20) 100%), #25262B',
    boxShadow: '0px -4px 16px 0px rgba(0, 0, 0, 0.10)',
  },
  picks: {
    display: 'flex',
    alignItems: 'center',
  },
  emptyPick: {
    ref: getRef('emptyPick'),
    width: theme.other.spacing._40,
    height: theme.other.spacing._40,
    borderRadius: '100em',
    overflow: 'hidden',
    backgroundImage: `url(${selectionImage.src})`,
    border: 'none',
  },
  pick: {
    ref: getRef('pick'),
  },
  pickWrapper: {
    width: theme.other.spacing._32 + 4,
    overflow: 'hidden',
    marginLeft: -4,

    [`& .${getRef('pick')}, & .${getRef('emptyPick')}`]: {
      marginLeft: theme.other.spacing._32 + 4 - theme.other.spacing._40,
    },
  },
  pickMessage: {
    ...textStyles['headline-small'],
    color: theme.white,
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing._4,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing._8,
  },
  buttonSmall: {
    padding: `0 ${theme.other.spacing._8}px`,
  },
  boostIcon: {
    width: theme.other.spacing._24,
    height: theme.other.spacing._24,
    borderRadius: '100%',
    backgroundImage: 'linear-gradient(90deg, #4BEBE2 0%, #DA47F7 100%)',
    padding: '1px',
    '& > div': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      backgroundImage:
        'linear-gradient(0deg, #1A1C1E, #1A1C1E), linear-gradient(90deg, rgba(75, 235, 226, 0.2) 0%, rgba(218, 71, 247, 0.2) 100%)',
    },
  },
}));

export { useStyles };
