import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';
import { handleRefreshInterceptor } from '~/services/interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '~/services/interceptors/set-auth-token.interceptor';

const gamesServiceInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_SPLASH}games-service/api`,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(gamesServiceInstance);
handleRefreshInterceptor(gamesServiceInstance);

export default gamesServiceInstance;
