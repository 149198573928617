import { getTeamOrPlayerImageUrl } from '~/domains/common/utils/getTeamOrPlayerImageUrl';

import type { TPropExpanded, TUserProp } from '../../services/interfaces/propsService/parsed/props';

export function parsePropToPlayerCard(prop: TPropExpanded | TUserProp) {
  const idForPlayerImage = prop.use_team_image ? prop.team_id : prop.entity_id;
  return {
    leagueImage: getTeamOrPlayerImageUrl(prop.league, prop.team_id),
    useTeamAlias: prop.use_team_image,
    teamAlias: prop.team?.alias,
    playerImage: getTeamOrPlayerImageUrl(prop.league, idForPlayerImage),
    playerFirstName: prop.entity?.player_details.first_name,
    playerLastName: prop.entity?.player_details.last_name,
    position: prop.entity?.player_details?.position,
    teamColor: prop.team?.primary_color || 'transparent',
    jersey: prop.entity?.player_details?.jersey_number
      ? `#${prop.entity.player_details.jersey_number}`
      : null,
  };
}
