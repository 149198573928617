import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';

import repeat from '~/common/utils/repeat';
import { Button } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';

import type { PropPickProps } from '../../../types/propPickProps.type';
import PlayerImageCircle from '../PlayerImageCircle';

import { useStyles } from './PicksPanel.styles';
import type { PublicBoost } from '~/domains/contest/domains/services/interfaces/propsService/api/boosts';
import { BoostIcon } from '../../Boosts/BoostIcon';

type PicksPanelProps = {
  boost: PublicBoost;
  picks: PropPickProps[];
  minPicks: number;
  maxPicks: number;
  payoutMultiplier?: number;
  errorMessage?: string;
  onViewEntryClick: () => void;
};

function PicksPanel({
  boost,
  picks = [],
  minPicks,
  maxPicks,
  payoutMultiplier,
  errorMessage,
  onViewEntryClick,
}: PicksPanelProps) {
  const { classes, cx, theme } = useStyles();
  const { t } = useTranslation('contest');
  const picksArray = useMemo(() => {
    if (picks.length < minPicks) {
      return [...picks, ...repeat(minPicks - picks.length, () => null)];
    }
    if (picks.length === maxPicks) {
      return picks;
    }
    return [...picks, null];
  }, [maxPicks, minPicks, picks]);

  return (
    <div className={classes.container}>
      <CSSTransition in={Boolean(errorMessage)} timeout={300}>
        <div className={classes.errorMessage}>
          <div className={classes.errorMessageContent}>{errorMessage}</div>
        </div>
      </CSSTransition>
      <div className={classes.mainPanel}>
        <div className={classes.picks}>
          {picksArray.map((pick, index) => {
            const zIndexStyle = { zIndex: picksArray.length - index };

            const content = pick ? (
              <div
                className={classes.pick}
                key={pick.id}
                style={index === 0 ? zIndexStyle : undefined}
              >
                <PlayerImageCircle src={pick.playerImage} alt={pick.playerName} isDark />
              </div>
            ) : (
              <div
                className={classes.emptyPick}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={index === 0 ? zIndexStyle : undefined}
              />
            );

            if (index === 0) {
              return content;
            }
            return (
              <div className={classes.pickWrapper} key={pick?.index ?? index} style={zIndexStyle}>
                {content}
              </div>
            );
          })}
        </div>
        {picks.length ? (
          <div className={classes.buttonWrapper}>
            {!!boost && (
              <div className={classes.boostIcon}>
                <div>
                  <BoostIcon iconUrl={boost.svg_url} size={16} />
                </div>
              </div>
            )}
            <Button
              className={cx({
                [classes.buttonSmall]: picks.length >= 6,
              })}
              variant="primary-fill"
              size="medium"
              onClick={onViewEntryClick}
            >
              <div className={classes.buttonContent}>
                <Text variant="headline-small" lh={1}>
                  {t('quickPick.board.picksPanel.viewEntry')}
                </Text>
                <Text variant="body-small" lh={1}>
                  {picks.length < minPicks
                    ? t('quickPick.board.picksPanel.minPicksRequired', { count: minPicks })
                    : t('quickPick.board.picksPanel.winUpTo', { payoutMultiplier })}
                </Text>
              </div>
            </Button>
          </div>
        ) : (
          <div className={classes.pickMessage}>
            {t('quickPick.board.picksPanel.addAtLeastAmountOfPicks', { count: minPicks })}
          </div>
        )}
      </div>
    </div>
  );
}

export default PicksPanel;
