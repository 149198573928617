import { createStyles } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';

import { Button, UnstyledButton } from '~/domains/design-system/Button';
import { textStyles } from '~/domains/design-system/Text';
import type { UserDirection } from '~/domains/contest/domains/services/interfaces/propsService/api';
import IconTrash from '~/domains/design-system/icons/IconTrash';

import PlayerImageCircle from '../../PlayerImageCircle';
import GameTeamsLabel from '../../GameTeamsLabel';
import type { PropPickProps } from '../../../../types/propPickProps.type';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing._8,
    padding: theme.other.spacing._16,
  },
  image: {
    flexShrink: 0,
  },
  pickInfo: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing._4,
    overflow: 'hidden',
  },
  titleAndPosition: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    ...textStyles['headline-medium'],
    color: theme.colors.gray2[6],
  },
  position: {
    ...textStyles['subhead-small'],
    color: theme.colors.gray2[3],
  },
  subtitle: {
    ...textStyles['body-medium'],
    color: theme.colors.gray2[3],

    '& strong': {
      ...textStyles['subhead-small'],
    },
  },
  line: {
    display: 'flex',
    gap: theme.other.spacing._8,
    alignItems: 'center',
    overflow: 'hidden',
  },
  lineValue: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: theme.other.fontFamily.hubotSans,
    fontStretch: '75%',
    fontWeight: 900,
    color: theme.colors.gray2[6],
  },
  lineLabel: {
    fontSize: '12px',
    fontFamily: theme.other.fontFamily.hubotSans,
    textAlign: 'right',

    fontStretch: '75%',
    color: theme.colors.gray2[6],
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexShrink: 1,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.other.spacing._8,
    flexShrink: 0,
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeButton: {
    padding: theme.other.spacing._8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',
      '&:hover': {
        opacity: 0.5,
      },
    },
  },
}));

export type PicksDrawerPickProps = PropPickProps & {
  onPickChange: (id: string, direction: UserDirection) => void;
  onPickRemove: (id: string) => void;
};

function PicksDrawerPick({
  awayTeam,
  direction,
  homeTeam,
  id,
  line,
  playerImage,
  playerName,
  playerTeamId,
  position,
  startDate,
  onPickChange,
  onPickRemove,
}: PicksDrawerPickProps) {
  const { classes, theme, cx } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.image}>
        <PlayerImageCircle src={playerImage} alt="Name" />
      </div>
      <div className={classes.pickInfo}>
        <div className={classes.titleAndPosition}>
          <div className={classes.title}>{playerName}</div>
          {position && (
            <div className={classes.position}>
              &nbsp;•&nbsp;
              {position}
            </div>
          )}
        </div>
        <div className={classes.subtitle}>
          <GameTeamsLabel homeTeam={homeTeam} awayTeam={awayTeam} playerTeamId={playerTeamId} />
          {' • '}
          {startDate}
        </div>
        <div className={classes.line}>
          <div className={classes.lineValue}>{line.value}</div>
          <div className={classes.lineLabel}>{line.label}</div>
        </div>
      </div>
      <div className={classes.actions}>
        <Button
          variant={direction === 'over' ? 'primary-fill' : 'tertiary-fill'}
          size="small"
          className={classes.action}
          onClick={() => {
            if (direction === 'over') {
              return;
            }
            onPickChange(id, 'over');
          }}
        >
          <IconArrowUp size={16} />
        </Button>
        <Button
          variant={direction === 'under' ? 'primary-fill' : 'tertiary-fill'}
          size="small"
          className={classes.action}
          onClick={() => {
            if (direction === 'under') {
              return;
            }
            onPickChange(id, 'under');
          }}
        >
          <IconArrowDown size={16} />
        </Button>
        <UnstyledButton
          className={cx(classes.action, classes.removeButton)}
          onClick={() => {
            onPickRemove(id);
          }}
        >
          <IconTrash color={theme.colors.gray2[2]} />
        </UnstyledButton>
      </div>
    </div>
  );
}

export default PicksDrawerPick;
