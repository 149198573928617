import useTranslation from 'next-translate/useTranslation';
import { useCallback, useMemo } from 'react';

import {
  reportQuickPicksPickEdit,
  reportQuickPicksPickMake,
  reportQuickPicksPickRemove,
} from '../analyticsEvents';
import { useBoardContext } from '../context/BoardContext/BoardContext';
import type { PropPickProps } from '../types/propPickProps.type';

import { useQuickPicksSettings } from './useQuickPicksSettings';

const DEFAULT_ERROR_TIMEOUT = 5_000;

function usePlayerCard() {
  const { t } = useTranslation('contest');
  const { picks, error } = useBoardContext();
  const settingsQuery = useQuickPicksSettings();
  const selectedPicksMap = useMemo(
    () => picks.value.reduce((acc, pick) => ({ ...acc, [pick.id]: pick }), {}),
    [picks.value]
  );

  const maxPicks = settingsQuery.data?.peer_to_peer_config?.options.slice(-1)[0]?.slip_length ?? 2;

  const handlePick = useCallback(
    (pick: PropPickProps) => {
      const isPicked = selectedPicksMap[pick.id];
      const isPickedAndSameDirection =
        isPicked && selectedPicksMap[pick.id].direction === pick.direction;
      if (isPicked) {
        if (isPickedAndSameDirection) {
          reportQuickPicksPickRemove({ location: 'playerCard' });
          picks.remove(pick.id);
        } else {
          reportQuickPicksPickEdit({ location: 'playerCard' });
          picks.edit(pick.id, pick.direction);
        }
      } else {
        const samePlayerDifferentProp = picks.value.find((p) => p.playerId === pick.playerId);
        if (samePlayerDifferentProp) {
          picks.remove(samePlayerDifferentProp.id);
          picks.add(pick);
          return;
        }
        if (picks.value.length >= maxPicks) {
          error.showError(
            t('quickPick.board.errors.maxEntriesReached', { count: maxPicks }),
            DEFAULT_ERROR_TIMEOUT
          );
          return;
        }

        reportQuickPicksPickMake({ location: 'playerCard' });
        picks.add(pick);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [picks.remove, picks.edit, picks.add, selectedPicksMap, maxPicks]
  );

  return {
    handlePick,
  };
}

export default usePlayerCard;
