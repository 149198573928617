import { ActionIcon, UnstyledButton } from '@mantine/core';
import { IconArrowDown, IconArrowUp, IconInfoCircle } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';

import silhouetteLight from '~/components/assets/silhouette-light.png';
import Image from '~/domains/common/components/Image/Image';
import { Button } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';
import dt from '~/testing';

import type { PropPickProps } from '../../../types/propPickProps.type';

import GameTeamsLabel from '../GameTeamsLabel';
import { useStyles } from './PlayerCell.styles';

export type PlayerCellProps = PropPickProps & {
  onPick: (pick: PropPickProps) => void;
  onHeaderClick?: (id: string) => void;
};

function PlayerCell({ onPick, onHeaderClick, ...pick }: PlayerCellProps) {
  const {
    leagueImage,
    useTeamAlias,
    teamColor,
    teamAlias,
    playerImage,
    playerName,
    position,
    awayTeam,
    homeTeam,
    playerTeamId,
    startDate,
    line,
    direction,
    id,
  } = pick;

  const { classes, cx, theme } = useStyles();
  const { t } = useTranslation('contest');

  const handleClick = () => () => onHeaderClick(id);

  return (
    <div
      data-test-id={dt.contest.quickPicks.playerCell.playerCell}
      className={cx(classes.playerCell, { [classes.selected]: !!direction })}
    >
      <UnstyledButton className={classes.playerCellHeader} onClick={handleClick()}>
        {onHeaderClick && (
          <ActionIcon className={classes.infoIcon}>
            <IconInfoCircle fill={theme.white} />
          </ActionIcon>
        )}
        {useTeamAlias && teamColor && (
          <Text className={classes.teamAlias} color={teamColor}>
            {teamAlias}
          </Text>
        )}
        {!useTeamAlias && leagueImage && (
          <div
            className={classes.playerCellHeaderBackground}
            style={{ backgroundImage: `url(${leagueImage})` }}
          />
        )}
        <div className={classes.playerCellHeaderImage}>
          <Image
            src={playerImage}
            width={108}
            height={108}
            fallbackComponent={
              <Image src={silhouetteLight} width={108} height={108} alt={playerName} />
            }
            alt={playerName}
          />
        </div>
        <div className={classes.playerCellProps}>
          <Text
            data-test-id={dt.contest.quickPicks.playerCell.passYdsNumber}
            className={classes.playerCellProps_value}
          >
            {line.value}
          </Text>
          <Text
            data-test-id={dt.contest.quickPicks.playerCell.playerYards}
            className={classes.playerCellProps_type}
          >
            {line.label}
          </Text>
        </div>
      </UnstyledButton>
      <div className={classes.playerCellBody}>
        <div className={classes.playerCellBody_name_position}>
          <Text
            data-test-id={dt.contest.quickPicks.playerCell.playerName}
            className={classes.playerCellBody_name}
          >
            {playerName}
          </Text>
          <span
            data-test-id={dt.contest.quickPicks.playerCell.playerPosition}
            className={classes.playerCellBody_position}
          >
            &nbsp;•&nbsp;
            {position}
          </span>
        </div>
        <Text
          data-test-id={dt.contest.quickPicks.playerCell.metaData}
          className={classes.playerCellBody_details}
        >
          <GameTeamsLabel homeTeam={homeTeam} awayTeam={awayTeam} playerTeamId={playerTeamId} />
          {' • '}
          {startDate}
        </Text>
      </div>
      <div className={classes.playerCellFooter}>
        <Button
          className={classes.playerCellFooter_button}
          variant={direction === 'over' ? 'primary-fill' : 'tertiary-fill'}
          onClick={(e) => {
            e.stopPropagation();
            onPick({ ...pick, direction: 'over' });
          }}
          size="small"
        >
          <IconArrowUp size={16} />
          {t('quickPick.board.playerCard.more')}
        </Button>
        <Button
          className={classes.playerCellFooter_button}
          variant={direction === 'under' ? 'primary-fill' : 'tertiary-fill'}
          onClick={(e) => {
            e.stopPropagation();
            onPick({ ...pick, direction: 'under' });
          }}
          size="small"
        >
          <IconArrowDown size={16} />
          {t('quickPick.board.playerCard.less')}
        </Button>
      </div>
    </div>
  );
}

export { PlayerCell };
