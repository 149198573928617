import { Skeleton } from '@mantine/core';

import GamecenterPlayerDetailHeader from '../../../gamecenter/domains/player/components/GamecenterPlayerDetail/GamecenterPlayerDetailHeader';
import type { GetPropResponse } from '../../../services/interfaces/propsService/api';
import { parsePropToPlayerCard } from '../../utils/parsePropToPlayerCard';

type PlayerCardHeaderProps = {
  player: GetPropResponse;
  isLoading: boolean;
};

function PlayerCardHeader({ player, isLoading }: PlayerCardHeaderProps) {
  if (isLoading) {
    return <Skeleton height={148} />;
  }

  const prop = parsePropToPlayerCard(player?.prop);

  return (
    <GamecenterPlayerDetailHeader
      useTeamAlias={prop.useTeamAlias}
      teamAlias={prop.teamAlias}
      leagueImage={prop.leagueImage}
      playerImage={prop.playerImage}
      playerName={`${prop.playerFirstName} ${prop.playerLastName}`}
      teamColor={prop.teamColor}
      tag={{
        position: prop.position,
        jerseyNumber: prop.jersey,
      }}
    />
  );
}

export default PlayerCardHeader;
