import { format } from 'date-fns';

import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type {
  PlayerGamesLogTable,
  PlayerGamesLogTableColumn,
} from '../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

const getOpp = (playerSeasonGame: PlayerSeasonGame): string => {
  const playerTeamId = playerSeasonGame.teamId;
  const homeTeam = playerSeasonGame.gameAttributes.home;
  const awayTeam = playerSeasonGame.gameAttributes.away;

  return homeTeam.id === playerTeamId ? awayTeam.alias : `@${homeTeam.alias}`;
};

const parseBasketballGames = (playerSeasonGames: PlayerSeasonGame[]): PlayerGamesLogTable => {
  const table: Record<string, PlayerGamesLogTableColumn> = {
    date: {
      label: 'Date',
      values: [],
    },
    opp: {
      label: 'OPP',
      values: [],
    },
    // fpts: {
    //   label: 'FPTS',
    //   values: [],
    // },
    min: {
      label: 'MIN',
      values: [],
    },
    pts: {
      label: 'PTS',
      values: [],
    },
    reb: {
      label: 'REB',
      values: [],
    },
    ast: {
      label: 'AST',
      values: [],
    },
    stl: {
      label: 'STL',
      values: [],
    },
    blk: {
      label: 'BLK',
      values: [],
    },
    fgma: {
      label: 'FGM/A',
      values: [],
    },
    _3pma: {
      label: '3PM/A',
      values: [],
    },
    ftma: {
      label: 'FTM/A',
      values: [],
    },
  };

  playerSeasonGames.forEach((playerSeasonGame) => {
    table.date.values.push(format(playerSeasonGame.gameStartDate, 'MM/dd'));
    table.opp.values.push(getOpp(playerSeasonGame));
    table.min.values.push(playerSeasonGame.playerActivity.minutes ?? '-');
    table.pts.values.push(playerSeasonGame.playerActivity.points ?? '-');
    table.reb.values.push(playerSeasonGame.playerActivity.rebounds ?? '-');
    table.ast.values.push(playerSeasonGame.playerActivity.assists ?? '-');
    table.stl.values.push(playerSeasonGame.playerActivity.steals ?? '-');
    table.blk.values.push(playerSeasonGame.playerActivity.blocks ?? '-');
    table.fgma.values.push(
      `${playerSeasonGame.playerActivity.field_goals_made}-${playerSeasonGame.playerActivity.field_goals_att}`
    );
    // eslint-disable-next-line no-underscore-dangle
    table._3pma.values.push(
      `${playerSeasonGame.playerActivity.three_points_made}-${playerSeasonGame.playerActivity.three_points_att}`
    );
    table.ftma.values.push(
      `${playerSeasonGame.playerActivity.free_throws_made}-${playerSeasonGame.playerActivity.free_throws_att}`
    );
  });

  return Object.entries(table).map(([_, value]) => value);
};

export default parseBasketballGames;
