import { Skeleton } from '@mantine/core';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';

import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';
import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';

import type { PlayerGamesLogTableColumn } from '../../../game/domains/common/dfs/types/gamesLogTable';
import type { PlayerGroupedStats } from '../../../game/domains/common/dfs/types/player';
import type { PropPickProps } from '../../types/propPickProps.type';

import { Sport } from '@betterpool/api-types/contests-service';
import { ActiveProp } from './ActiveProp';
import { OtherProps } from './OtherProps';
import PlayerCardStatsTab from './PlayerCardStatsTab';
import { Tabs } from './Tabs';

type PlayerCardProps = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  isLoading: boolean;
  groupedStats: PlayerGroupedStats;
  playerSeasonData: UseInfiniteQueryResult<PaginatedData<PlayerSeasonGame>> & {
    items: PlayerGamesLogTableColumn[];
  };
  handlePick: (pick: PropPickProps) => void;
  activePropData: PropPickProps;
  otherPropsData: PropPickProps[];
  selectedPick: PropPickProps;
  sport: Sport;
};

function PlayerCard({
  activeTab,
  setActiveTab,
  isLoading,
  groupedStats,
  playerSeasonData,
  handlePick,
  activePropData,
  otherPropsData,
  selectedPick,
  sport,
}: PlayerCardProps) {
  const showGameLogTab = sport !== Sport.HOCKEY;
  return (
    <>
      {showGameLogTab && <Tabs setActiveTab={setActiveTab} />}
      {activeTab === 'props' && (
        <>
          <Skeleton visible={isLoading} mb={isLoading ? 16 : 0}>
            <ActiveProp
              handlePick={handlePick}
              selectedPick={selectedPick}
              propData={activePropData}
            />
          </Skeleton>
          <Skeleton height={340} visible={isLoading}>
            <OtherProps
              selectedPick={selectedPick}
              propData={otherPropsData}
              handlePick={handlePick}
            />
          </Skeleton>
        </>
      )}
      {activeTab === 'stats' && (
        // Height is 442 to match ActiveProp + OtherProps heights
        <Skeleton visible={isLoading} height={442}>
          <PlayerCardStatsTab
            groupedStats={groupedStats}
            tableData={playerSeasonData?.items}
            tableFetchNextPage={playerSeasonData.fetchNextPage}
            tableHasNextPage={playerSeasonData.hasNextPage}
            isTableFetchingNextPage={playerSeasonData.isFetchingNextPage}
          />
        </Skeleton>
      )}
    </>
  );
}

export default PlayerCard;
