import { createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import Modal from '~/domains/common/components/Modal/Modal/Modal';
import { Button } from '~/domains/design-system/Button';
import { textStyles } from '~/domains/design-system/Text';

import type { EntryPicksChangedAlertPickProps } from './EntryPicksChangedAlertPick';
import EntryPicksChangedAlertPick from './EntryPicksChangedAlertPick';

const useStyles = createStyles((theme) => ({
  modal: {
    backgroundColor: theme.colors.gray2[5],
    color: theme.white,
  },
  drawer: {
    borderTopLeftRadius: theme.other.spacing._16,
    borderTopRightRadius: theme.other.spacing._16,
    backgroundColor: theme.colors.gray2[5],
  },
  drawerBody: {
    borderTopLeftRadius: theme.other.spacing._16,
    borderTopRightRadius: theme.other.spacing._16,
    paddingTop: theme.other.spacing._16,
    maxHeight: '100svh',
    overflow: 'hidden',
  },
  content: {
    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      maxHeight: `calc(100svh - ${theme.other.spacing._16 * 2}px)`,
    },
  },
  title: {
    ...textStyles['headline-medium'],
    color: theme.white,
  },
  text: {
    ...textStyles['body-medium'],
    color: theme.colors.gray2[1],
    marginTop: theme.other.spacing._8,
  },
  submit: {
    backgroundColor: theme.colors.yellow[3],
    color: theme.colors.gray2[6],
    flexShrink: 0,
    marginTop: theme.other.spacing._16,
  },
  picksContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing._8,
    marginTop: theme.other.spacing._8,
    overflow: 'auto',
    flexShrink: 1,
  },
}));

function EntryPicksChangedAlert({
  picks,
  isOpened,
  onSubmit,
}: {
  picks: EntryPicksChangedAlertPickProps[];
  isOpened: boolean;
  onSubmit: () => void;
}) {
  const { t } = useTranslation('contest');
  const { classes } = useStyles();

  return (
    <Modal
      isOpened={isOpened}
      onClose={() => {}}
      classNames={{
        modal: {
          modal: classes.modal,
        },
        drawer: {
          drawer: classes.drawer,
          body: classes.drawerBody,
        },
      }}
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <div className={classes.content}>
        <div className={classes.title}>{t('quickPick.board.entryChangedAlert.title')}</div>
        <div className={classes.text}>{t('quickPick.board.entryChangedAlert.text')}</div>
        <div className={classes.picksContainer}>
          {picks.map((pickProps) => (
            <EntryPicksChangedAlertPick key={pickProps.oldProp.id} {...pickProps} />
          ))}
        </div>
        <Button
          variant="primary-fill"
          className={classes.submit}
          fullWidth
          size="medium"
          onClick={onSubmit}
        >
          {t('quickPick.board.entryChangedAlert.submit')}
        </Button>
      </div>
    </Modal>
  );
}

export default EntryPicksChangedAlert;
