import { format } from 'date-fns';
import { Badge, Tooltip } from '@mantine/core';

import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type {
  PlayerGamesLogTable,
  PlayerGamesLogTableColumn,
} from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

const parseBaseStats = (playerSeasonGames: PlayerSeasonGame[]): PlayerGamesLogTable => {
  const table: Record<string, PlayerGamesLogTableColumn> = {
    date: {
      label: 'DATE',
      values: [],
    },
    game: {
      label: 'GAME',
      values: [],
    },
  };

  playerSeasonGames.forEach((playerSeasonGame) => {
    const { gameStartDate, gameAttributes, season } = playerSeasonGame;

    const isPreseason = !!season?.name?.includes('PRE');
    table.date.values.push(
      <>
        {format(new Date(gameStartDate), 'M/d/yy')}
        {isPreseason ? (
          <>
            &nbsp;
            <Tooltip label="Preseason" position="top" withArrow>
              <Badge color="dark" variant="filled" size="xs">
                P
              </Badge>
            </Tooltip>
          </>
        ) : null}
      </>
    );
    table.game.values.push(`${gameAttributes.home.alias}@${gameAttributes.away.alias}`);
  });

  return Object.entries(table).map(([_, value]) => value);
};

export default parseBaseStats;
