import { createQueryString } from '~/domains/common/utils/queryString';
import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';

import type { GameDetail } from '../interfaces/gameDetail.interface';
import type { PlayerDetail } from '../interfaces/player-detail.interface';
import type { APIPlayerSeasonGame, PlayerSeasonGame } from '../interfaces/player-season.interface';
import type { Slate as OldSlate } from '../interfaces/slates.interface';

import { type Slate } from '~/services/games-service/interfaces/slates.interface';
import gamesServiceInstance from './games-service.service.instance';
import parsePlayerSeasonData from './parsers/parsePlayerSeasonData';

const getSlates = async (scheduleId: string, authToken?: string): Promise<OldSlate[]> => {
  const response = await gamesServiceInstance.get<OldSlate[]>(`/schedules/${scheduleId}/slates`, {
    headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined,
  });
  return response.data;
};

const getSlate = async (slateId: string, authToken?: string): Promise<Slate> => {
  const response = await gamesServiceInstance.get<Slate>(`/slates/${slateId}`, {
    headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined,
  });
  return response.data;
};

const getGameDetail = async (gameId: string, contestId?: string): Promise<GameDetail> => {
  const query = createQueryString({
    contestId,
  });

  const response = await gamesServiceInstance.get<GameDetail>(`/games/${gameId}${query}`);
  return response.data;
};

const getPlayerDetail = async ({
  playerId,
  slateId,
  seasonId,
  contestId,
}: {
  playerId: string;
  slateId?: string;
  seasonId?: string;
  contestId?: string;
}): Promise<PlayerDetail> => {
  const query = createQueryString({
    slateId,
    seasonId,
    contestId,
  });
  const response = await gamesServiceInstance.get<PlayerDetail>(`/players/${playerId}${query}`);
  return response.data;
};

const getPlayerSeasonData = async ({
  playerId,
  seasonId,
  limit,
  offset,
  seasonTypes = ['REG', 'POST'],
}: {
  playerId: string;
  seasonId?: string;
  limit?: number;
  offset?: number;
  seasonTypes?: ('REG' | 'POST' | 'PRE')[];
}): Promise<PaginatedData<PlayerSeasonGame>> => {
  const query = createQueryString({
    seasonId,
    limit,
    offset,
    seasonTypes,
  });
  const response = await gamesServiceInstance.get<PaginatedData<APIPlayerSeasonGame>>(
    `/players/${playerId}/player-activities${query}`
  );

  return {
    ...response.data,
    data: parsePlayerSeasonData(response.data?.data ?? []),
  };
};

export { getGameDetail, getPlayerDetail, getPlayerSeasonData, getSlate, getSlates };
