import { Sport } from '@betterpool/api-types/contests-service';
import { Flex, Modal } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useState } from 'react';

import { Button } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';
import usePlayerDetail from '~/domains/games/hooks/usePlayerDetail';

import usePlayerGamesLog from '../../../gamecenter/domains/player/hooks/usePlayerGamesLog';
import parsePlayerGroupedStats from '../../../gamecenter/domains/player/parsers/parsePlayerGroupedStats/parsePlayerGroupedStats';
import { useBoardContext } from '../../context/BoardContext/BoardContext';
import usePlayerCard from '../../hooks/usePlayerCard';
import { useQuickPicksPlayerCard } from '../../hooks/useQuickPicksPlayerCard';
import { parsePropToComponentProp } from '../../utils/parsePropToComponentProp';

import PlayerCardModal from './PlayerCardModal';

const SportsLeaguesMap = {
  nfl: Sport.FOOTBALL,
  nba: Sport.BASKETBALL,
  pga: Sport.GOLF,
  nhl: Sport.HOCKEY,
  ncaab: Sport.BASKETBALL,
};

type ConnectedPlayerCardProps = {
  playerId?: string;
  opened: boolean;
  onClose: () => void;
};

function ConnectedPlayerCard({ playerId, opened, onClose }: ConnectedPlayerCardProps) {
  const [activeTab, setActiveTab] = useState<string>('props');
  const { t } = useTranslation('contest');
  const {
    data: player,
    isLoading: isPlayerLoading,
    error: playerError,
  } = useQuickPicksPlayerCard(playerId);
  const { filters, picks } = useBoardContext();
  const { handlePick } = usePlayerCard();

  useEffect(() => {
    if (!opened) {
      setActiveTab('props');
    }
  }, [opened]);

  const sport = SportsLeaguesMap[player?.prop?.league];
  const playerSeasonData = usePlayerGamesLog({
    playerId: player?.prop?.entity_id,
    sport,
    playerPosition: player?.prop?.entity?.player_details?.position,
    enabled: !!player,
  });

  const {
    data: playerDetail,
    isLoading: isPlayerDetailLoading,
    error: playerDetailError,
  } = usePlayerDetail({
    playerId: player?.prop?.entity_id,
  });

  const groupedStats = useMemo(() => {
    if (!playerDetail) {
      return;
    }
    return parsePlayerGroupedStats(playerDetail, sport);
  }, [sport, playerDetail]);

  const activePropData = player
    ? parsePropToComponentProp(player?.prop, filters.query.flattenedTypes)
    : null;
  const otherPropsData = player?.included.map((prop) =>
    prop.status === 'active' ? parsePropToComponentProp(prop, filters.query.flattenedTypes) : null
  );
  const selectedPick = picks.value.find(
    (pick) => pick.id === player?.prop?.id || player?.included.find((prop) => prop.id === pick.id)
  );

  const handleErrorModalClose = () => {
    filters.reset();
  };

  if (playerId && (playerError || playerDetailError)) {
    return (
      <Modal
        opened={playerId && (!!playerError || !!playerDetailError)}
        onClose={handleErrorModalClose}
        centered
        withCloseButton={false}
        size="sm"
      >
        <Flex direction="column" align="center" justify="center" gap="md">
          <Text variant="body-large">{t('quickPick.playerCard.error.message')}</Text>
          <Button fullWidth onClick={handleErrorModalClose}>
            {t('quickPick.playerCard.error.cta')}
          </Button>
        </Flex>
      </Modal>
    );
  }

  const isLoading = isPlayerLoading || isPlayerDetailLoading || !playerDetail || !player;

  return (
    <PlayerCardModal
      opened={opened}
      onClose={onClose}
      activeTab={activeTab}
      player={player}
      setActiveTab={setActiveTab}
      isLoading={isLoading}
      groupedStats={groupedStats}
      playerSeasonData={playerSeasonData}
      handlePick={handlePick}
      activePropData={activePropData}
      otherPropsData={otherPropsData}
      selectedPick={selectedPick}
      sport={sport}
    />
  );
}

export default ConnectedPlayerCard;
