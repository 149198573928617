import { createStyles } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';

import { Button } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';

import { useTheme } from '@emotion/react';
import { BarChart } from '~/domains/design-system/components/charts/BarChart/BarChart';
import type { PropPickProps } from '../../types/propPickProps.type';

export type ActivePropProps = {
  handlePick: (pick: PropPickProps) => void;
  selectedPick: PropPickProps;
  propData: PropPickProps;
};

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: '14px',
    fontWeight: 900,
    marginBottom: '16px',
  },
  wrapper: {
    borderRadius: theme.other.spacing._8,
    border: `1px solid  ${theme.colors.gray[4]}`,
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    marginBottom: theme.other.spacing._16,
    overflow: 'hidden',
  },
  prop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.other.spacing._16,
  },
  propData: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  lineValue: {
    fontSize: '24px',
    fontWeight: 900,
    lineHeight: '32px',
  },
  lineLabel: {
    fontFamily: theme.other.fontFamily.hubotSans,
    fontStretch: '75%',
    color: theme.colors.gray2[7],
    fontSize: '14px',
    lineHeight: '20px',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.other.spacing._4,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 4px 8px 4px',
    gap: '4px',
    width: '75px',
  },
  chartWrapper: {
    backgroundColor: theme.colors.gray2[0],
    paddingBottom: theme.other.spacing._16,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing._16,
  },
  chartStats: {
    marginLeft: theme.other.spacing._16,
  },
}));

function ActiveProp({ selectedPick, propData, handlePick }: ActivePropProps) {
  const { t } = useTranslation('contest');
  const theme = useTheme();
  const { classes } = useStyles();

  const isSelected = selectedPick?.id === propData?.id;

  const onClick = useCallback(
    (pick: PropPickProps, direction: 'over' | 'under') => () => {
      handlePick({ ...pick, direction });
    },
    [handlePick]
  );

  if (!propData) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.prop}>
        <div className={classes.propData}>
          <Text className={classes.lineValue}>{propData.line.value}</Text>
          <Text className={classes.lineLabel} transform="uppercase" size={14}>
            {propData.line.label}
          </Text>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            variant={
              isSelected && selectedPick?.direction === 'over' ? 'primary-fill' : 'tertiary-fill'
            }
            onClick={onClick(propData, 'over')}
            size="small"
          >
            <IconArrowUp size={16} />
            {t('quickPick.board.playerCard.more')}
          </Button>
          <Button
            className={classes.button}
            variant={
              isSelected && selectedPick?.direction === 'under' ? 'primary-fill' : 'tertiary-fill'
            }
            onClick={onClick(propData, 'under')}
            size="small"
          >
            <IconArrowDown size={16} />
            {t('quickPick.board.playerCard.less')}
          </Button>
        </div>
      </div>
      {propData.chartProps && (
        <div className={classes.chartWrapper}>
          {/* TODO: invert colors based on League */}
          <BarChart invertColors {...propData.chartProps} />
          <div className={classes.chartStats}>
            <Text color={theme.colors.gray2[3]}>
              {t('quickPick.playerCard.lastXAvg', {
                count: propData.chartProps.tickCount,
              })}
            </Text>
            <Text fw={900}>{propData.chartProps.lastXAvg}</Text>
          </div>
        </div>
      )}
    </div>
  );
}

export { ActiveProp };
