import { useMutation } from '@tanstack/react-query';

import type { ValidateBoostPayload } from '../../services/interfaces/propsService/api/boosts';
import { validateBoost } from '../../services/props.service.api';
import QuickPickQueryKeys from '../constants/query';

const useQuickPicksValidateBoost = () =>
  useMutation({
    mutationFn: (payload: ValidateBoostPayload) => validateBoost(payload),
    mutationKey: QuickPickQueryKeys.VALIDATE_BOOST(),
  });

export default useQuickPicksValidateBoost;
