import { createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import Modal from '~/domains/common/components/Modal/Modal/Modal';
import type { PublicBoostTerms } from '~/domains/contest/domains/services/interfaces/propsService/api/boosts';
import { Button } from '~/domains/design-system/Button';
import { Pill } from '~/domains/design-system/Pill';
import { Text } from '~/domains/design-system/Text';
import { useBoardContext } from '../../../context/BoardContext/BoardContext';
import BoostBanner from '../BoostBanner';

const CLOSE_BUTTON_HEIGHT = 48;
const useStyles = createStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100% - ${CLOSE_BUTTON_HEIGHT}px)`,
    gap: theme.other.spacing._16,
    overflow: 'auto',
    height: '60vh',
    fontSize: '10px',
    marginBottom: `${CLOSE_BUTTON_HEIGHT + 16}px`,
    padding: '0 16px',
    a: {
      color: theme.colors.splash[3],
      textDecoration: 'none',
    },
  },
  modal: {
    padding: '0 !important',
    borderTopLeftRadius: theme.other.spacing._32,
    borderTopRightRadius: theme.other.spacing._32,
  },
  closeButtonContainer: {
    position: 'absolute',
    bottom: 16,
    left: 16,
    right: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    width: 'calc(100% - 32px)',
    height: `${CLOSE_BUTTON_HEIGHT}px`,
  },
  header: {
    borderBottom: `1px solid ${theme.colors.gray2[0]}`,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 16px 0 16px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    gap: theme.other.spacing._16,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing._8,
  },
  closeButton: {
    padding: '0',
  },
  descriptionText: {
    color: theme.colors.gray2[6],
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.other.spacing._8,
    flexWrap: 'wrap',
  },
  pill: {
    display: 'flex',
    padding: '2px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '50px',
    background:
      'linear-gradient(90deg, rgba(75, 235, 226, 0.20) 0%, rgba(218, 71, 247, 0.20) 100%), #F1F3F5',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        opacity: 1,
      },
    },
  },
  list: {
    margin: 0,
    paddingLeft: theme.other.spacing._16,
  },
}));

type BoostTermsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  title: string;
  description: string;
  expiresAt: Date;
  callout: string;
  terms: PublicBoostTerms;
  onCTAClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon: string;
};

function BoostTermsModal({
  isOpen,
  onClose,
  id,
  title,
  description,
  expiresAt,
  callout,
  terms,
  onCTAClick,
  icon,
}: BoostTermsModalProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('contest');
  const { selectedBoostId } = useBoardContext();

  const parseLinks = (text: string) =>
    text.replace(
      /{link:(.*?)\|(.*?)}/g,
      (_, p1, p2) => `<a href="${p1}" target="_blank" rel="noopener noreferrer">${p2}</a>`
    );

  const handleCTAClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onCTAClick(e);
    onClose();
  };

  return (
    <Modal
      isOpened={isOpen}
      classNames={{
        drawer: {
          body: classes.modal,
        },
        modal: {
          modal: classes.modal,
        },
      }}
      onClose={onClose}
      withCloseButton={false}
    >
      <div className={classes.body}>
        <div className={classes.header}>
          <div className={classes.headerContent}>
            <Text variant="headline-medium">{t('quickPick.boost.terms')}</Text>
            <Button className={classes.closeButton} onClick={onClose} variant="text">
              <Text variant="title-large">{t('quickPick.boost.close')}</Text>
            </Button>
          </div>
        </div>
        <div className={classes.content}>
          <BoostBanner
            id={id}
            title={title}
            description={description}
            expiresAt={expiresAt}
            callout={callout}
            icon={icon}
          />
          <div className={classes.section}>
            <Text transform="uppercase" variant="display-headline-expanded-medium">
              {terms?.type_title}
            </Text>
            <Text variant="body-large" color="gray2.6">
              {terms?.type_description}
            </Text>
          </div>
          <div className={classes.tags}>
            {terms?.tags?.map((tag) => (
              <Pill key={tag} className={classes.pill}>
                <Text variant="subhead-x-small" color="gray2.7">
                  {tag}
                </Text>
              </Pill>
            ))}
          </div>
          <div className={classes.section}>
            <Text transform="uppercase" variant="display-headline-expanded-medium">
              Eligibility
            </Text>
            {terms?.eligibility?.paragraphs.map((p) => (
              <Text key={p} variant="body-medium" color="gray2.6">
                {p}
              </Text>
            ))}
            {terms?.eligibility?.ordered_list && (
              <ol className={classes.list}>
                {terms?.eligibility?.ordered_list.map((item) => <li key={item}>{item}</li>)}
              </ol>
            )}
          </div>
          <div className={classes.section}>
            <Text transform="uppercase" variant="display-headline-expanded-medium">
              Rules
            </Text>
            {terms?.rules?.paragraphs.map((p) => (
              <Text
                key={p}
                variant="body-medium"
                color="gray2.6"
                dangerouslySetInnerHTML={{ __html: parseLinks(p) }}
              />
            ))}
            {terms?.rules?.ordered_list && (
              <ol className={classes.list}>
                {terms?.rules?.ordered_list.map((item) => <li key={item}>{item}</li>)}
              </ol>
            )}
          </div>
        </div>
      </div>
      <div className={classes.closeButtonContainer}>
        <Button
          onClick={handleCTAClick}
          fullWidth
          variant={selectedBoostId === id ? 'tertiary-fill' : 'primary-fill'}
        >
          <Text variant="label-medium">
            {selectedBoostId === id
              ? t('quickPick.boost.removePromotion')
              : t('quickPick.boost.applyPromotion')}
          </Text>
        </Button>
      </div>
    </Modal>
  );
}

export default BoostTermsModal;
