import type { Sport } from '@betterpool/api-types/contests-service';
import { useMemo } from 'react';

import usePlayerSeasonData from '~/domains/games/hooks/usePlayerSeasonData';

import parsePlayerGamesLog from '../parsers/parsePlayerGamesLog/parsePlayerGamesLog';

const usePlayerGamesLog = ({
  playerId,
  playerPosition,
  sport,
  enabled = true,
}: {
  playerId: string;
  playerPosition?: string;
  sport: Sport;
  enabled?: boolean;
}) => {
  const playerSeasonData = usePlayerSeasonData({ playerId, enabled });

  const items = useMemo(() => {
    if (!playerSeasonData.data) {
      return;
    }

    const data = playerSeasonData.data.pages.reduce((acc, page) => [...acc, ...page.data], []);

    return parsePlayerGamesLog(data, sport, playerPosition);
  }, [playerPosition, playerSeasonData.data, sport]);

  return {
    ...playerSeasonData,
    items,
  };
};

export default usePlayerGamesLog;
