import { createContext } from 'react';

import type { ConfirmationModalContextValue } from './types';

export const ConfirmationModalContext = createContext<ConfirmationModalContextValue>({
  isOpen: false,
  payload: {},
  setIsOpen: () => {},
  setRemainingTime: () => {},
  setOnConfirmLoading: () => {},
});
