import { format } from 'date-fns';

import { golfPlayerStatusKey } from '~/common/constants/golf-player-status-key.const';
import type { PlayerSeasonGame } from '~/domains/games/interfaces/player-season.interface';

import type {
  PlayerGamesLogTable,
  PlayerGamesLogTableColumn,
} from '../../components/GamecenterPlayerDetail/GamecenterPlayerDetailGameLog';

const getScore = (value = 0) => {
  if (value > 0) {
    return `+${value}`;
  }
  if (value === 0) {
    return 'E';
  }
  return `${value}`;
};

const getPosition = (stats: PlayerSeasonGame) => {
  if (stats.playerActivity?.competing_status) {
    return (
      golfPlayerStatusKey[stats?.playerActivity?.competing_status]?.value ??
      stats.playerActivity?.position
    );
  }
  return stats.playerActivity?.position;
};

const parseGolfGames = (playerSeasonGames: PlayerSeasonGame[]): PlayerGamesLogTable => {
  const table: Record<string, PlayerGamesLogTableColumn> = {
    date: {
      label: 'Date',
      values: [],
    },
    course: {
      label: 'EVENT',
      values: [],
    },
    pos: {
      label: 'POS',
      values: [],
    },
    score: {
      label: 'SCR',
      values: [],
    },
  };

  playerSeasonGames.forEach((playerSeasonGame) => {
    table.date.values.push(format(playerSeasonGame.gameStartDate, 'MM/dd'));
    table.course.values.push(playerSeasonGame.gameName);
    table.pos.values.push(getPosition(playerSeasonGame));
    table.score.values.push(getScore(playerSeasonGame.playerActivity.score));
  });

  return Object.entries(table).map(([_, value]) => value);
};

export default parseGolfGames;
