import Trans from 'next-translate/Trans';
import type { PropPickProps } from '../../types/propPickProps.type';

function GameTeamsLabel({
  homeTeam,
  awayTeam,
  playerTeamId,
}: Pick<PropPickProps, 'homeTeam' | 'awayTeam' | 'playerTeamId'>) {
  if (playerTeamId === awayTeam.id) {
    return (
      <Trans
        i18nKey="contest:quickPick.board.playerCard.awayAtHome"
        values={{
          awayTeam: awayTeam.alias,
          homeTeam: homeTeam.alias,
        }}
        components={{
          strong: <strong />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="contest:quickPick.board.playerCard.homeVsAway"
      values={{
        awayTeam: awayTeam.alias,
        homeTeam: homeTeam.alias,
      }}
      components={{
        strong: <strong />,
      }}
    />
  );
}

export default GameTeamsLabel;
